export default {
  // Options.jsx
  items_per_page: '/ stronÄ',
  jump_to: 'IdÅº do',
  jump_to_confirm: 'potwierdzaÄ',
  page: '',

  // Pagination.jsx
  prev_page: 'Poprzednia strona',
  next_page: 'NastÄpna strona',
  prev_5: 'Poprzednie 5 stron',
  next_5: 'NastÄpne 5 stron',
  prev_3: 'Poprzednie 3 strony',
  next_3: 'NastÄpne 3 strony',
};
