import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, ElementRef, EventEmitter,
  Input, Output
} from '@angular/core';

import { toBoolean } from '../core/util/convert';

export class NzOptionSelectionChange {
  constructor(
    public source: NzAutocompleteOptionComponent,
    public isUserInput: boolean = false
  ) {
  }
}

@Component({
  selector           : 'nz-auto-option',
  preserveWhitespaces: false,
  changeDetection    : ChangeDetectionStrategy.OnPush,
  templateUrl        : './nz-autocomplete-option.component.html',
  host               : {
    'role'                                          : 'menuitem',
    'class'                                         : 'ant-select-dropdown-menu-item',
    '[class.ant-select-dropdown-menu-item-selected]': 'selected',
    '[class.ant-select-dropdown-menu-item-active]'  : 'active',
    '[class.ant-select-dropdown-menu-item-disabled]': 'nzDisabled',
    '[attr.aria-selected]'                          : 'selected.toString()',
    '[attr.aria-disabled]'                          : 'nzDisabled.toString()',
    '(click)'                                       : 'selectViaInteraction()'
  }
})
export class NzAutocompleteOptionComponent {
  private disabled = false;

  active = false;
  selected = false;

  /* tslint:disable-next-line:no-any */
  @Input() nzValue: any;
  @Input() nzLabel: string;

  @Input()
  get nzDisabled(): boolean {
    return this.disabled;
  }

  set nzDisabled(value: boolean) {
    this.disabled = toBoolean(value);
  }

  @Output() selectionChange = new EventEmitter<NzOptionSelectionChange>();

  constructor(private changeDetectorRef: ChangeDetectorRef, private element: ElementRef) {
  }

  /** éæ© */
  select(): void {
    this.selected = true;
    this.changeDetectorRef.markForCheck();
    this.emitSelectionChangeEvent();
  }

  /** åæ¶éæ© */
  deselect(): void {
    this.selected = false;
    this.changeDetectorRef.markForCheck();
    this.emitSelectionChangeEvent();
  }

  /** è·åç¨äºæ¾ç¤ºç label */
  getLabel(): string {
    return this.nzLabel || this.nzValue.toString();
  }

  /** è®¾ç½®æ¿æ´»æ ·å¼ (ä»éæ ·å¼) */
  setActiveStyles(): void {
    if (!this.active) {
      this.active = true;
      this.changeDetectorRef.markForCheck();
    }
  }

  /** è®¾ç½®éæ¿æ´»æ ·å¼ (ä»éæ ·å¼) */
  setInactiveStyles(): void {
    if (this.active) {
      this.active = false;
      this.changeDetectorRef.markForCheck();
    }
  }

  scrollIntoViewIfNeeded(): void {
    /* tslint:disable-next-line:no-string-literal */
    if (this.element.nativeElement && this.element.nativeElement['scrollIntoViewIfNeeded']) {
      /* tslint:disable-next-line:no-string-literal */
      setTimeout(() =>  this.element.nativeElement[ 'scrollIntoViewIfNeeded' ](false), 150);
    }
  }

  private emitSelectionChangeEvent(isUserInput: boolean = false): void {
    this.selectionChange.emit(new NzOptionSelectionChange(this, isUserInput));
  }

  selectViaInteraction(): void {
    if (!this.disabled) {
      this.selected = !this.selected;
      if (this.selected) {
        this.setActiveStyles();
      } else {
        this.setInactiveStyles();
      }
      this.emitSelectionChangeEvent(true);
      this.changeDetectorRef.markForCheck();
    }
  }

}
