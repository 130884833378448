import Calendar from './calendar/uk_UA';
import DatePicker from './date-picker/uk_UA';
import Pagination from './pagination/uk_UA';
import TimePicker from './time-picker/uk_UA';

export default {
  locale: 'uk',
  Pagination,
  DatePicker,
  TimePicker,
  Calendar,
  Table: {
    filterTitle: 'Ð¤ÑÐ»ÑÑÑÑÐ²Ð°ÑÐ¸',
    filterConfirm: 'OK',
    filterReset: 'Ð¡ÐºÐ¸Ð½ÑÑÐ¸',
    emptyText: 'ÐÐ°Ð½Ð¸Ñ Ð½ÐµÐ¼Ð°Ñ',
    selectAll: 'ÐÐ±ÑÐ°ÑÐ¸ Ð²ÑÑ',
    selectInvert: 'ÐÐ½Ð²ÐµÑÑÑÐ²Ð°ÑÐ¸ Ð²Ð¸Ð±ÑÑ',
  },
  Modal: {
    okText: 'ÐÐ°ÑÐ°Ð·Ð´',
    cancelText: 'Ð¡ÐºÐ°ÑÑÐ²Ð°ÑÐ¸',
    justOkText: 'ÐÐ°ÑÐ°Ð·Ð´',
  },
  Popconfirm: {
    okText: 'ÐÐ°ÑÐ°Ð·Ð´',
    cancelText: 'Ð¡ÐºÐ°ÑÑÐ²Ð°ÑÐ¸',
  },
  Transfer: {
    notFoundContent: 'ÐÑÑÐ¾Ð³Ð¾ Ð½Ðµ Ð·Ð½Ð°Ð¹Ð´ÐµÐ½Ð¾',
    searchPlaceholder: 'ÐÐ²ÐµÐ´ÑÑÑ ÑÐµÐºÑÑ Ð´Ð»Ñ Ð¿Ð¾ÑÑÐºÑ',
    itemUnit: 'item',
    itemsUnit: 'items',
  },
  Select: {
    notFoundContent: 'ÐÑÑÐ¾Ð³Ð¾ Ð½Ðµ Ð·Ð½Ð°Ð¹Ð´ÐµÐ½Ð¾',
  },
  Upload: {
    uploading: 'ÐÐ°Ð²Ð°Ð½ÑÐ°Ð¶ÐµÐ½Ð½Ñ ...',
    removeFile: 'ÐÐ¸Ð´Ð°Ð»Ð¸ÑÐ¸ ÑÐ°Ð¹Ð»',
    uploadError: 'ÐÐ¾Ð¼Ð¸Ð»ÐºÐ° Ð·Ð°Ð²Ð°Ð½ÑÐ°Ð¶ÐµÐ½Ð½Ñ',
    previewFile: 'ÐÐ¾Ð¿ÐµÑÐµÐ´Ð½ÑÐ¹ Ð¿ÐµÑÐµÐ³Ð»ÑÐ´ ÑÐ°Ð¹Ð»Ñ',
  },
};
