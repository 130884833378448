export default {
  today: 'ä»æ¥',
  now: 'ç¾å¨æå»',
  backToToday: 'ä»æ¥ã«æ»ã',
  ok: 'æ±ºå®',
  timeSelect: 'æéãé¸æ',
  dateSelect: 'æ¥æãé¸æ',
  clear: 'ã¯ãªã¢',
  month: 'æ',
  year: 'å¹´',
  previousMonth: 'åæ (ãã¼ã¸ã¢ããã­ã¼)',
  nextMonth: 'ç¿æ (ãã¼ã¸ãã¦ã³ã­ã¼)',
  monthSelect: 'æãé¸æ',
  yearSelect: 'å¹´ãé¸æ',
  decadeSelect: 'å¹´ä»£ãé¸æ',
  yearFormat: 'YYYYå¹´',
  dayFormat: 'Dæ¥',
  dateFormat: 'YYYYå¹´MæDæ¥',
  dateTimeFormat: 'YYYYå¹´MæDæ¥ HHæmmåssç§',
  previousYear: 'åå¹´ (Controlãæ¼ããªããå·¦ã­ã¼)',
  nextYear: 'ç¿å¹´ (Controlãæ¼ããªããå³ã­ã¼)',
  previousDecade: 'åã®å¹´ä»£',
  nextDecade: 'æ¬¡ã®å¹´ä»£',
  previousCentury: 'åã®ä¸ç´',
  nextCentury: 'æ¬¡ã®ä¸ç´',
};
