// This icon file is generated by build/generate.ts
// tslint:disable

import { IconDefinition } from '../types';

export const BookFill: IconDefinition = {
  name: 'book',
  theme: 'fill',
  icon:
    '<svg  viewBox="64 64 896 896"><path d="M832 64H192c-17.7 0-32 14.3-32 32v832c0 17.7 14.3 32 32 32h640c17.7 0 32-14.3 32-32V96c0-17.7-14.3-32-32-32zM668 345.9L621.5 312 572 347.4V124h96v221.9z"/></svg>'
};
