import Calendar from './calendar/bg_BG';
import DatePicker from './date-picker/bg_BG';
import Pagination from './pagination/bg_BG';
import TimePicker from './time-picker/bg_BG';

export default {
  locale: 'bg',
  Pagination,
  DatePicker,
  TimePicker,
  Calendar,
  Table: {
    filterTitle: 'Ð¤Ð¸Ð»ÑÑÐ¸ÑÐ°Ð½Ðµ',
    filterConfirm: 'ÐÐ¾Ð±ÑÐµ',
    filterReset: 'ÐÑÐ»ÑÐ¸Ð°Ð½Ðµ',
    emptyText: 'ÐÑÐ¼Ð° Ð´Ð°Ð½Ð½Ð¸',
    selectAll: 'ÐÐ·Ð±Ð¾Ñ Ð½Ð° ÑÐµÐºÑÑÐ° ÑÑÑÐ°Ð½Ð¸ÑÐ°',
    selectInvert: 'ÐÐ±ÑÑÑÐ°Ð½Ðµ',
  },
  Modal: {
    okText: 'ÐÐ¾Ð±ÑÐµ',
    cancelText: 'ÐÑÐºÐ°Ð·',
    justOkText: 'ÐÐ¾Ð±ÑÐµ',
  },
  Popconfirm: {
    okText: 'ÐÐ¾Ð±ÑÐµ',
    cancelText: 'ÐÑÐºÐ°Ð·',
  },
  Transfer: {
    notFoundContent: 'ÐÑÐ¼Ð° Ð½Ð°Ð¼ÐµÑÐµÐ½Ð¸',
    searchPlaceholder: 'Ð¢ÑÑÑÐµÐ½Ðµ',
    itemUnit: 'Ð¸Ð·Ð±Ð¾Ñ',
    itemsUnit: 'Ð¸Ð·Ð±Ð¾ÑÐ¸',
  },
  Select: {
    notFoundContent: 'ÐÑÐ¼Ð° Ð½Ð°Ð¼ÐµÑÐµÐ½Ð¸',
  },
  Upload: {
    uploading: 'ÐÐ°ÑÐ²Ð°Ð½Ðµ...',
    removeFile: 'ÐÑÐµÐ¼Ð°ÑÐ²Ð°Ð½Ðµ',
    uploadError: 'ÐÑÐµÑÐºÐ° Ð¿ÑÐ¸ ÐºÐ°ÑÐ²Ð°Ð½ÐµÑÐ¾',
    previewFile: 'ÐÑÐµÐ³Ð»ÐµÐ´',
  },
};
