export default {
  // Options.jsx
  items_per_page: '/ lehekÃ¼ljel',
  jump_to: 'HÃ¼ppa',
  jump_to_confirm: 'Kinnitage',
  page: '',

  // Pagination.jsx
  prev_page: 'Eelmine leht',
  next_page: 'JÃ¤rgmine leht',
  prev_5: 'Eelmised 5 lehekÃ¼lge',
  next_5: 'JÃ¤rgmised 5 lehekÃ¼lge',
  prev_3: 'Eelmised 3 lehekÃ¼lge',
  next_3: 'JÃ¤rgmised 3 lehekÃ¼lge',
};
