export default {
  // Options.jsx
  items_per_page: '/ÑÑÑÐ°Ð½Ð¸ÑÐµ',
  jump_to: 'ÐÐµÑÐµÐ¹ÑÐ¸',
  jump_to_confirm: 'Ð¿Ð¾Ð´ÑÐ²ÐµÑÐ´Ð¸ÑÑ',
  page: '',

  // Pagination.jsx
  prev_page: 'ÐÐ°Ð·Ð°Ð´',
  next_page: 'ÐÐ¿ÐµÑÐµÐ´',
  prev_5: 'ÐÑÐµÐ´ÑÐ´ÑÑÐ¸Ðµ 5',
  next_5: 'Ð¡Ð»ÐµÐ´ÑÑÑÐ¸Ðµ 5',
  prev_3: 'ÐÑÐµÐ´ÑÐ´ÑÑÐ¸Ðµ 3',
  next_3: 'Ð¡Ð»ÐµÐ´ÑÑÑÐ¸Ðµ 3',
};
