// This icon file is generated by build/generate.ts
// tslint:disable

import { IconDefinition } from '../types';

export const SmallDashOutline: IconDefinition = {
  name: 'small-dash',
  theme: 'outline',
  icon:
    '<svg  viewBox="64 64 896 896"><path d="M112 476h72v72h-72zm182 0h72v72h-72zm364 0h72v72h-72zm182 0h72v72h-72zm-364 0h72v72h-72z"/></svg>'
};
