// This icon file is generated by build/generate.ts
// tslint:disable

import { IconDefinition } from '../types';

export const MobileFill: IconDefinition = {
  name: 'mobile',
  theme: 'fill',
  icon:
    '<svg  viewBox="64 64 896 896"><path d="M744 62H280c-35.3 0-64 28.7-64 64v768c0 35.3 28.7 64 64 64h464c35.3 0 64-28.7 64-64V126c0-35.3-28.7-64-64-64zM512 824c-22.1 0-40-17.9-40-40s17.9-40 40-40 40 17.9 40 40-17.9 40-40 40z"/></svg>'
};
