export default {
  today: 'I dag',
  now: 'NÃ¥',
  backToToday: 'GÃ¥ til i dag',
  ok: 'Ok',
  clear: 'Annuller',
  month: 'MÃ¥ned',
  year: 'Ãr',
  timeSelect: 'Velg tidspunkt',
  dateSelect: 'Velg dato',
  monthSelect: 'Velg mÃ¥ned',
  yearSelect: 'Velg Ã¥r',
  decadeSelect: 'Velg Ã¥rti',
  yearFormat: 'YYYY',
  dateFormat: 'DD.MM.YYYY',
  dayFormat: 'DD',
  dateTimeFormat: 'DD.MM.YYYY HH:mm:ss',
  monthBeforeYear: true,
  previousMonth: 'Forrige mÃ¥ned(PageUp)',
  nextMonth: 'Neste mÃ¥ned (PageDown)',
  previousYear: 'Forrige Ã¥r (Control + left)',
  nextYear: 'Neste Ã¥r (Control + right)',
  previousDecade: 'Forrige tiÃ¥r',
  nextDecade: 'Neste tiÃ¥r',
  previousCentury: 'Forrige Ã¥rhundre',
  nextCentury: 'Neste Ã¥rhundre',
};
