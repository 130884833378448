import Calendar from './calendar/pt_BR';
import DatePicker from './date-picker/pt_BR';
import Pagination from './pagination/pt_BR';
import TimePicker from './time-picker/pt_BR';

export default {
  locale: 'pt-br',
  Pagination,
  DatePicker,
  TimePicker,
  Calendar,
  Table: {
    filterTitle: 'Filtro',
    filterConfirm: 'OK',
    filterReset: 'Resetar',
    emptyText: 'NÃ£o hÃ¡ dados',
    selectAll: 'Selecionar pÃ¡gina atual',
    selectInvert: 'Inverter seleÃ§Ã£o',
  },
  Modal: {
    okText: 'OK',
    cancelText: 'Cancelar',
    justOkText: 'OK',
  },
  Popconfirm: {
    okText: 'OK',
    cancelText: 'Cancelar',
  },
  Transfer: {
    notFoundContent: 'NÃ£o encontrado',
    searchPlaceholder: 'Procurar',
    itemUnit: 'item',
    itemsUnit: 'items',
  },
  Select: {
    notFoundContent: 'NÃ£o encontrado',
  },
  Upload: {
    uploading: 'Enviando...',
    removeFile: 'Remover arquivo',
    uploadError: 'Erro no envio',
    previewFile: 'Visualizar arquivo',
  },
};
