// This icon file is generated by build/generate.ts
// tslint:disable

import { IconDefinition } from '../types';

export const GitlabFill: IconDefinition = {
  name: 'gitlab',
  theme: 'fill',
  icon:
    '<svg  viewBox="64 64 896 896"><path d="M910.5 553.2l-109-370.8c-6.8-20.4-23.1-34.1-44.9-34.1s-39.5 12.3-46.3 32.7l-72.2 215.4H386.2L314 181.1c-6.8-20.4-24.5-32.7-46.3-32.7s-39.5 13.6-44.9 34.1L113.9 553.2c-4.1 13.6 1.4 28.6 12.3 36.8l385.4 289 386.7-289c10.8-8.1 16.3-23.1 12.2-36.8z"/></svg>'
};
