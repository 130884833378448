// This icon file is generated by build/generate.ts
// tslint:disable

import { IconDefinition } from '../types';

export const SkinFill: IconDefinition = {
  name: 'skin',
  theme: 'fill',
  icon:
    '<svg  viewBox="64 64 896 896"><path d="M870 126H663.8c-17.4 0-32.9 11.9-37 29.3C614.3 208.1 567 246 512 246s-102.3-37.9-114.8-90.7a37.93 37.93 0 0 0-37-29.3H154a44 44 0 0 0-44 44v252a44 44 0 0 0 44 44h75v388a44 44 0 0 0 44 44h478a44 44 0 0 0 44-44V466h75a44 44 0 0 0 44-44V170a44 44 0 0 0-44-44z"/></svg>'
};
