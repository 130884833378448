// This icon file is generated by build/generate.ts
// tslint:disable

import { IconDefinition } from '../types';

export const MinusOutline: IconDefinition = {
  name: 'minus',
  theme: 'outline',
  icon:
    '<svg  viewBox="64 64 896 896"><path d="M872 474H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h720c4.4 0 8-3.6 8-8v-60c0-4.4-3.6-8-8-8z"/></svg>'
};
