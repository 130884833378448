import CalendarLocale from '../calendar/el_GR';
import TimePickerLocale from '../time-picker/el_GR';

// Merge into a locale object
const locale = {
  lang: {
    placeholder: 'ÎÏÎ¹Î»Î­Î¾ÏÎµ Î·Î¼ÎµÏÎ¿Î¼Î·Î½Î¯Î±',
    rangePlaceholder: ['ÎÏÏÎ¹ÎºÎ® Î·Î¼ÎµÏÎ¿Î¼Î·Î½Î¯Î±', 'Î¤ÎµÎ»Î¹ÎºÎ® Î·Î¼ÎµÏÎ¿Î¼Î·Î½Î¯Î±'],
    ...CalendarLocale,
  },
  timePickerLocale: {
    ...TimePickerLocale,
  },
};

// All settings at:
// https://github.com/ant-design/ant-design/issues/424

export default locale;
