// This icon file is generated by build/generate.ts
// tslint:disable

import { IconDefinition } from '../types';

export const DesktopOutline: IconDefinition = {
  name: 'desktop',
  theme: 'outline',
  icon:
    '<svg  viewBox="64 64 896 896"><path d="M928 140H96c-17.7 0-32 14.3-32 32v496c0 17.7 14.3 32 32 32h380v112H304c-8.8 0-16 7.2-16 16v48c0 4.4 3.6 8 8 8h432c4.4 0 8-3.6 8-8v-48c0-8.8-7.2-16-16-16H548V700h380c17.7 0 32-14.3 32-32V172c0-17.7-14.3-32-32-32zm-40 488H136V212h752v416z"/></svg>'
};
