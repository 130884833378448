// This icon file is generated by build/generate.ts
// tslint:disable

import { IconDefinition } from '../types';

export const HourglassFill: IconDefinition = {
  name: 'hourglass',
  theme: 'fill',
  icon:
    '<svg  viewBox="64 64 896 896"><path d="M742 318V184h86c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8H196c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h86v134c0 81.5 42.4 153.2 106.4 194-64 40.8-106.4 112.5-106.4 194v134h-86c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h632c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8h-86V706c0-81.5-42.4-153.2-106.4-194 64-40.8 106.4-112.5 106.4-194z"/></svg>'
};
