export default {
  // Options.jsx
  items_per_page: '/ pÃ gina',
  jump_to: 'Anar a',
  jump_to_confirm: 'Confirma',
  page: '',

  // Pagination.jsx
  prev_page: 'PÃ gina prÃ¨via',
  next_page: 'PÃ gina segÃ¼ent',
  prev_5: '5 pÃ gines prÃ¨vies',
  next_5: '5 pÃ gines segÃ¼ents',
  prev_3: '3 pÃ gines prÃ¨vies',
  next_3: '3 pÃ gines segÃ¼ents',
};
