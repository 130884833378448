// This icon file is generated by build/generate.ts
// tslint:disable

import { IconDefinition } from '../types';

export const CalendarFill: IconDefinition = {
  name: 'calendar',
  theme: 'fill',
  icon:
    '<svg  viewBox="64 64 896 896"><path d="M112 880c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V460H112v420zm768-696H712v-64c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v64H384v-64c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v64H144c-17.7 0-32 14.3-32 32v176h800V216c0-17.7-14.3-32-32-32z"/></svg>'
};
