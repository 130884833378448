import Calendar from './calendar/sv_SE';
import DatePicker from './date-picker/sv_SE';
import Pagination from './pagination/sv_SE';
import TimePicker from './time-picker/sv_SE';

export default {
  locale: 'sv',
  Pagination,
  DatePicker,
  TimePicker,
  Calendar,
  Table: {
    filterTitle: 'Filtermeny',
    filterConfirm: 'OK',
    filterReset: 'Rensa',
    emptyText: 'Ingen information',
  },
  Modal: {
    okText: 'OK',
    cancelText: 'Avbryt',
    justOkText: 'OK',
  },
  Popconfirm: {
    okText: 'OK',
    cancelText: 'Avbryt',
  },
  Transfer: {
    notFoundContent: 'Info saknas',
    searchPlaceholder: 'SÃ¶k',
    itemUnit: 'element',
    itemsUnit: 'element',
  },
  Select: {
    notFoundContent: 'Info saknas',
  },
};
