import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Input, NgZone,
  Output,
  QueryList,
  TemplateRef,
  ViewChild, ViewChildren
} from '@angular/core';
import { defer, merge, Observable, Subscription } from 'rxjs';
import { filter, switchMap, take } from 'rxjs/operators';

import { toBoolean } from '../core/util/convert';

import { dropDownAnimation } from '../core/animation/dropdown-animations';
import { NzAutocompleteOptionComponent, NzOptionSelectionChange } from './nz-autocomplete-option.component';

export interface AutocompleteDataSourceItem {
  value: string;
  label: string;
}

export type AutocompleteDataSource = AutocompleteDataSourceItem[] | string[] | number[];

@Component({
  selector           : 'nz-autocomplete',
  preserveWhitespaces: false,
  changeDetection    : ChangeDetectionStrategy.OnPush,
  animations         : [
    dropDownAnimation
  ],
  templateUrl        : './nz-autocomplete.component.html',
  styles             : [
    `
    .ant-select-dropdown {
      top: 100%;
      left: 0;
      position: relative;
      width: 100%;
      margin-top: 4px;
      margin-bottom: 4px;
    }
    `
  ]
})
export class NzAutocompleteComponent implements AfterViewInit {
  private activeItemIndex: number = -1;
  private selectionChangeSubscription: Subscription;

  showPanel: boolean = false;
  isOpen: boolean = false;
  activeItem: NzAutocompleteOptionComponent;
  dropDownPosition: 'top' | 'center' | 'bottom' = 'bottom';

  /** ç»ä»¶æ¯æè®¾ç½® dataSource å content è®¾ç½® options
   *  è¿ä¸ªå±æ§ä¸ºå¶æä¾æ¹ä¾¿çè®¿é®æ¹å¼ */
  get options(): QueryList<NzAutocompleteOptionComponent> {
    // ä¼åä½¿ç¨ dataSource
    if (this.nzDataSource) {
      return this.fromDataSourceOptions;
    } else {
      return this.fromContentOptions;
    }
  }

  /** æä¾ç» cdk-overlay ç¨äºæ¸²æ */
  @ViewChild(TemplateRef) template: TemplateRef<{}>;

  @ViewChild('panel') panel: ElementRef;
  @ViewChild('content') content: ElementRef;

  /** ç± Content æä¾ options */
  @ContentChildren(NzAutocompleteOptionComponent, { descendants: true }) fromContentOptions: QueryList<NzAutocompleteOptionComponent>;

  /** ç± nzDataSource æä¾ options */
  @ViewChildren(NzAutocompleteOptionComponent) fromDataSourceOptions: QueryList<NzAutocompleteOptionComponent>;

  /** èªå®ä¹å®½åº¦åä½ px */
  @Input() nzWidth: number;

  /** æ¯å¦é»è®¤é«äº®ç¬¬ä¸ä¸ªéé¡¹ï¼é»è®¤ `true` */
  @Input()
  get nzDefaultActiveFirstOption(): boolean {
    return this._defaultActiveFirstOption;
  }

  set nzDefaultActiveFirstOption(value: boolean) {
    this._defaultActiveFirstOption = toBoolean(value);
  }

  _defaultActiveFirstOption: boolean = true;

  /** ä½¿ç¨é®çéæ©éé¡¹çæ¶åæéä¸­é¡¹åå¡«å°è¾å¥æ¡ä¸­ï¼é»è®¤ `false` */
  @Input()
  get nzBackfill(): boolean {
    return this._backfill;
  }

  set nzBackfill(value: boolean) {
    this._backfill = toBoolean(value);
  }

  _backfill: boolean = false;

  /** èªå¨å®æçæ°æ®æº */
  @Input()
  get nzDataSource(): AutocompleteDataSource {
    return this._dataSource;
  }

  set nzDataSource(value: AutocompleteDataSource) {
    this._dataSource = value;
  }

  _dataSource: AutocompleteDataSource;

  /** éæ©æ¶ååºçäºä»¶ */
  @Output() selectionChange: EventEmitter<NzAutocompleteOptionComponent> = new EventEmitter<NzAutocompleteOptionComponent>();

  /** ç¨äºç»ä»¶åé¨çå¬ options çéæ©åå */
  readonly optionSelectionChanges: Observable<NzOptionSelectionChange> = defer(() => {
    if (this.options) {
      return merge(...this.options.map(option => option.selectionChange));
    }
    return this._ngZone.onStable
    .asObservable()
    .pipe(take(1), switchMap(() => this.optionSelectionChanges));
  });

  constructor(private changeDetectorRef: ChangeDetectorRef, private _ngZone: NgZone) {
  }

  ngAfterViewInit(): void {
    this.optionsInit();
  }

  setVisibility(): void {
    this.showPanel = !!this.options.length;
    this.changeDetectorRef.markForCheck();
  }

  setActiveItem(index: number): void {
    const activeItem = this.options.toArray()[ index ];
    if (activeItem && !activeItem.active) {
      this.activeItem = activeItem;
      this.activeItemIndex = index;
      this.clearSelectedOptions(this.activeItem);
      this.activeItem.setActiveStyles();
      this.changeDetectorRef.markForCheck();
    }
  }

  setNextItemActive(): void {
    const nextIndex = this.activeItemIndex + 1 <= this.options.length - 1 ? this.activeItemIndex + 1 : 0;
    this.setActiveItem(nextIndex);
  }

  setPreviousItemActive(): void {
    const previousIndex = this.activeItemIndex - 1 < 0 ? this.options.length - 1 : this.activeItemIndex - 1;
    this.setActiveItem(previousIndex);
  }

  getOptionIndex(option: NzAutocompleteOptionComponent): number | undefined {
    return this.options.reduce((result: number, current: NzAutocompleteOptionComponent, index: number) => {
      return result === undefined ? (option === current ? index : undefined) : result;
    }, undefined);
  }

  private optionsInit(): void {
    this.setVisibility();
    this.subscribeOptionChanges();
    const changes = this.nzDataSource ? this.fromDataSourceOptions.changes : this.fromContentOptions.changes;

    // ç¨äºå¤çå¨æ/å¼æ­¥ç options
    changes.subscribe(e => {
      if (!e.dirty && this.isOpen) {
        setTimeout(_ => this.setVisibility());
      }
      this.subscribeOptionChanges();
    });
  }

  /**
   * æ¸é¤ Options çæ¿æ´»ç¶æ
   */
  private clearSelectedOptions(skip?: NzAutocompleteOptionComponent, deselect: boolean = false): void {
    this.options.forEach(option => {
      if (option !== skip) {
        if (deselect) {
          option.deselect();
        }
        option.setInactiveStyles();
      }
    });
  }

  private subscribeOptionChanges(): void {
    this.selectionChangeSubscription = this.optionSelectionChanges
    .pipe(filter((event: NzOptionSelectionChange) => event.isUserInput))
    .subscribe((event: NzOptionSelectionChange) => {
      event.source.select();
      event.source.setActiveStyles();
      this.activeItem = event.source;
      this.activeItemIndex = this.getOptionIndex(this.activeItem);
      this.clearSelectedOptions(event.source, true);
      this.selectionChange.emit(event.source);
    });
  }
}
