export default {
  // Options.jsx
  items_per_page: '/ ÑÑÐ¾ÑÑÐ½ÑÑ',
  jump_to: 'ÐÐµÑÐµÐ¹ÑÐ¸',
  jump_to_confirm: 'Ð¿ÑÐ´ÑÐ²ÐµÑÐ´Ð¸ÑÐ¸',
  page: '',

  // Pagination.jsx
  prev_page: 'ÐÐ¾Ð¿ÐµÑÐµÐ´Ð½Ñ ÑÑÐ¾ÑÑÐ½ÐºÐ°',
  next_page: 'ÐÐ°ÑÑÑÐ¿Ð½Ð° ÑÑÐ¾ÑÑÐ½ÐºÐ°',
  prev_5: 'ÐÐ¾Ð¿ÐµÑÐµÐ´Ð½Ñ 5 ÑÑÐ¾ÑÑÐ½Ð¾Ðº',
  next_5: 'ÐÐ°ÑÑÑÐ¿Ð½Ñ 5 ÑÑÐ¾ÑÑÐ½Ð¾Ðº',
  prev_3: 'ÐÐ¾Ð¿ÐµÑÐµÐ´Ð½Ñ 3 ÑÑÐ¾ÑÑÐ½ÐºÐ¸',
  next_3: 'ÐÐ°ÑÑÑÐ¿Ð½Ñ 3 ÑÑÐ¾ÑÑÐ½ÐºÐ¸',
};
