export default {
  // Options.jsx
  items_per_page: '/ strana',
  jump_to: 'ChoÄ na',
  jump_to_confirm: 'potvrdit',
  page: '',

  // Pagination.jsx
  prev_page: 'PredchÃ¡dzajÃºca strana',
  next_page: 'NasledujÃºca strana',
  prev_5: 'PredchÃ¡dzajÃºcich 5 strÃ¡n',
  next_5: 'NasledujÃºcich 5 strÃ¡n',
  prev_3: 'PredchÃ¡dzajÃºce 3 strany',
  next_3: 'NasledujÃºce 3 strany',
};
