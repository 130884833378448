import CalendarLocale from '../calendar/ja_JP';
import TimePickerLocale from '../time-picker/ja_JP';

const locale = {
  lang: {
    placeholder: 'æ¥ä»ãé¸æ',
    rangePlaceholder: ['éå§æ¥ä»', 'çµäºæ¥ä»'],
    ...CalendarLocale,
  },
  timePickerLocale: {
    ...TimePickerLocale,
  },
};

// All settings at:
// https://github.com/ant-design/ant-design/blob/master/components/date-picker/locale/example.json

export default locale;
