// This icon file is generated by build/generate.ts
// tslint:disable

import { IconDefinition } from '../types';

export const RollbackOutline: IconDefinition = {
  name: 'rollback',
  theme: 'outline',
  icon:
    '<svg  viewBox="64 64 896 896"><path d="M793 242H366v-74c0-6.7-7.7-10.4-12.9-6.3l-142 112a8 8 0 0 0 0 12.6l142 112c5.2 4.1 12.9.4 12.9-6.3v-74h415v470H175c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h618c35.3 0 64-28.7 64-64V306c0-35.3-28.7-64-64-64z"/></svg>'
};
