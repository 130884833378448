import CalendarLocale from '../calendar/pt_PT';
import TimePickerLocale from '../time-picker/pt_PT';

// Merge into a locale object
const locale = {
  lang: {
    ...CalendarLocale,
    placeholder: 'Data',
    rangePlaceholder: ['Data inicial', 'Data final'],
    today: 'Hoje',
    now: 'Agora',
    backToToday: 'Hoje',
    ok: 'Ok',
    clear: 'Limpar',
    month: 'MÃªs',
    year: 'Ano',
    timeSelect: 'Hora',
    dateSelect: 'Selecionar data',
    monthSelect: 'Selecionar mÃªs',
    yearSelect: 'Selecionar ano',
    decadeSelect: 'Selecionar dÃ©cada',
    yearFormat: 'YYYY',
    dateFormat: 'D/M/YYYY',
    dayFormat: 'D',
    dateTimeFormat: 'D/M/YYYY HH:mm:ss',
    monthFormat: 'MMMM',
    monthBeforeYear: false,
    previousMonth: 'MÃªs anterior (PageUp)',
    nextMonth: 'MÃªs seguinte (PageDown)',
    previousYear: 'Ano anterior (Control + left)',
    nextYear: 'Ano seguinte (Control + right)',
    previousDecade: 'Ãltima dÃ©cada',
    nextDecade: 'PrÃ³xima dÃ©cada',
    previousCentury: 'Ãltimo sÃ©culo',
    nextCentury: 'PrÃ³ximo sÃ©culo',
  },
  timePickerLocale: {
    ...TimePickerLocale,
    placeholder: 'Hora',
  },
};

// All settings at:
// https://github.com/ant-design/ant-design/blob/master/components/date-picker/locale/example.json

export default locale;
