export default {
  today: 'ì¤ë',
  now: 'íì¬ ìê°',
  backToToday: 'ì¤ëë¡ ëìê°ê¸°',
  ok: 'íì¸',
  clear: 'ì§ì°ê¸°',
  month: 'ì',
  year: 'ë',
  timeSelect: 'ìê° ì í',
  dateSelect: 'ë ì§ ì í',
  monthSelect: 'ë¬ ì í',
  yearSelect: 'ì° ì í',
  decadeSelect: 'ì°ë ì í',
  yearFormat: 'YYYYë',
  dateFormat: 'YYYY-MM-DD',
  dayFormat: 'Do',
  dateTimeFormat: 'YYYY-MM-DD HH:mm:ss',
  monthBeforeYear: false,
  previousMonth: 'ì´ì  ë¬ (PageUp)',
  nextMonth: 'ë¤ì ë¬ (PageDown)',
  previousYear: 'ì´ì  í´ (Control + left)',
  nextYear: 'ë¤ì í´ (Control + right)',
  previousDecade: 'ì´ì  ì°ë',
  nextDecade: 'ë¤ì ì°ë',
  previousCentury: 'ì´ì  ì¸ê¸°',
  nextCentury: 'ë¤ì ì¸ê¸°',
};
