export default {
  // Options.jsx
  items_per_page: '/ ÑÑÑÐ°Ð½Ð¸ÑÐ°',
  jump_to: 'ÐÑÐ¼',
  jump_to_confirm: 'Ð¿Ð¾ÑÐ²ÑÑÐ¶Ð´Ð°Ð²Ð°Ð¼',
  page: '',

  // Pagination.jsx
  prev_page: 'ÐÑÐµÐ´Ð¸ÑÐ½Ð° ÑÑÑÐ°Ð½Ð¸ÑÐ°',
  next_page: 'Ð¡Ð»ÐµÐ´Ð²Ð°ÑÐ° ÑÑÑÐ°Ð½Ð¸ÑÐ°',
  prev_5: 'ÐÑÐµÐ´Ð¸ÑÐ½Ð¸ 5 ÑÑÑÐ°Ð½Ð¸ÑÐ¸',
  next_5: 'Ð¡Ð»ÐµÐ´Ð²Ð°ÑÐ¸ 5 ÑÑÑÐ°Ð½Ð¸ÑÐ¸',
  prev_3: 'ÐÑÐµÐ´Ð¸ÑÐ½Ð¸ 3 ÑÑÑÐ°Ð½Ð¸ÑÐ¸',
  next_3: 'Ð¡Ð»ÐµÐ´Ð²Ð°ÑÐ¸ 3 ÑÑÑÐ°Ð½Ð¸ÑÐ¸',
};
