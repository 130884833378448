export default {
  today: 'Dnes',
  now: 'Teraz',
  backToToday: 'SpÃ¤Å¥ na dnes',
  ok: 'Ok',
  clear: 'VymazaÅ¥',
  month: 'Mesiac',
  year: 'Rok',
  timeSelect: 'VybraÅ¥ Äas',
  dateSelect: 'VybraÅ¥ dÃ¡tum',
  monthSelect: 'VybraÅ¥ mesiac',
  yearSelect: 'VybraÅ¥ rok',
  decadeSelect: 'VybraÅ¥ dekÃ¡du',
  yearFormat: 'YYYY',
  dateFormat: 'D.M.YYYY',
  dayFormat: 'D',
  dateTimeFormat: 'D.M.YYYY HH:mm:ss',
  monthBeforeYear: true,
  previousMonth: 'PredchÃ¡dzajÃºci mesiac (PageUp)',
  nextMonth: 'NasledujÃºci mesiac (PageDown)',
  previousYear: 'PredchÃ¡dzajÃºci rok (Control + left)',
  nextYear: 'NasledujÃºci rok (Control + right)',
  previousDecade: 'PredchÃ¡dzajÃºca dekÃ¡da',
  nextDecade: 'NasledujÃºca dekÃ¡da',
  previousCentury: 'PredchÃ¡dzajÃºce storoÄie',
  nextCentury: 'NasledujÃºce storoÄie',
};
