// This icon file is generated by build/generate.ts
// tslint:disable

import { IconDefinition } from '../types';

export const StopFill: IconDefinition = {
  name: 'stop',
  theme: 'fill',
  icon:
    '<svg  viewBox="64 64 896 896"><path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm234.8 736.5L223.5 277.2c16-19.7 34-37.7 53.7-53.7l523.3 523.3c-16 19.6-34 37.7-53.7 53.7z"/></svg>'
};
