// This icon file is generated by build/generate.ts
// tslint:disable

import { IconDefinition } from '../types';

export const StepForwardFill: IconDefinition = {
  name: 'step-forward',
  theme: 'fill',
  icon:
    '<svg viewBox="0 0 1024 1024"><path d="M676.4 528.95L293.2 829.97c-14.25 11.2-35.2 1.1-35.2-16.95V210.97c0-18.05 20.95-28.14 35.2-16.94l383.2 301.02a21.53 21.53 0 0 1 0 33.9M694 864h64a8 8 0 0 0 8-8V168a8 8 0 0 0-8-8h-64a8 8 0 0 0-8 8v688a8 8 0 0 0 8 8"/></svg>'
};
