export default {
  today: 'Dnes',
  now: 'NynÃ­',
  backToToday: 'ZpÄt na dneÅ¡ek',
  ok: 'Ok',
  clear: 'Vymazat',
  month: 'MÄsÃ­c',
  year: 'Rok',
  timeSelect: 'Vybrat Äas',
  dateSelect: 'Vybrat datum',
  monthSelect: 'Vyberte mÄsÃ­c',
  yearSelect: 'Vyberte rok',
  decadeSelect: 'Vyberte dekÃ¡du',
  yearFormat: 'YYYY',
  dateFormat: 'D.M.YYYY',
  dayFormat: 'D',
  dateTimeFormat: 'D.M.YYYY HH:mm:ss',
  monthBeforeYear: true,
  previousMonth: 'PÅedchozÃ­ mÄsÃ­c (PageUp)',
  nextMonth: 'NÃ¡sledujÃ­cÃ­ (PageDown)',
  previousYear: 'PÅedchozÃ­ rok (Control + left)',
  nextYear: 'NÃ¡sledujÃ­cÃ­ rok (Control + right)',
  previousDecade: 'PÅedchozÃ­ dekÃ¡da',
  nextDecade: 'NÃ¡sledujÃ­cÃ­ dekÃ¡da',
  previousCentury: 'PÅedchozÃ­ stoletÃ­',
  nextCentury: 'NÃ¡sledujÃ­cÃ­ stoletÃ­',
};
