// This icon file is generated by build/generate.ts
// tslint:disable

import { IconDefinition } from '../types';

export const MinusCircleFill: IconDefinition = {
  name: 'minus-circle',
  theme: 'fill',
  icon:
    '<svg  viewBox="64 64 896 896"><path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm192 472c0 4.4-3.6 8-8 8H328c-4.4 0-8-3.6-8-8v-48c0-4.4 3.6-8 8-8h368c4.4 0 8 3.6 8 8v48z"/></svg>'
};
