// This icon file is generated by build/generate.ts
// tslint:disable

import { IconDefinition } from '../types';

export const SwapLeftOutline: IconDefinition = {
  name: 'swap-left',
  theme: 'outline',
  icon:
    '<svg viewBox="0 0 1024 1024"><path d="M872 572H266.8l144.3-183c4.1-5.2.4-13-6.3-13H340c-9.8 0-19.1 4.5-25.1 12.2l-164 208c-16.5 21-1.6 51.8 25.1 51.8h696c4.4 0 8-3.6 8-8v-60c0-4.4-3.6-8-8-8z"/></svg>'
};
