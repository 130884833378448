// This icon file is generated by build/generate.ts
// tslint:disable

import { IconDefinition } from '../types';

export const LayoutFill: IconDefinition = {
  name: 'layout',
  theme: 'fill',
  icon:
    '<svg  viewBox="64 64 896 896"><path d="M384 912h496c17.7 0 32-14.3 32-32V340H384v572zm496-800H384v164h528V144c0-17.7-14.3-32-32-32zm-768 32v736c0 17.7 14.3 32 32 32h176V112H144c-17.7 0-32 14.3-32 32z"/></svg>'
};
