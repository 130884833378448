import Calendar from './calendar/ru_RU';
import DatePicker from './date-picker/ru_RU';
import Pagination from './pagination/ru_RU';
import TimePicker from './time-picker/ru_RU';

export default {
  locale: 'ru',
  Pagination,
  DatePicker,
  TimePicker,
  Calendar,
  Table: {
    filterTitle: 'Ð¤Ð¸Ð»ÑÑÑ',
    filterConfirm: 'OK',
    filterReset: 'Ð¡Ð±ÑÐ¾ÑÐ¸ÑÑ',
    emptyText: 'ÐÐµÑ Ð´Ð°Ð½Ð½ÑÑ',
    selectAll: 'ÐÑÐ±ÑÐ°ÑÑ Ð²ÑÑ',
    selectInvert: 'ÐÐ½Ð²ÐµÑÑÐ¸ÑÐ¾Ð²Ð°ÑÑ Ð²ÑÐ±Ð¾Ñ',
  },
  Modal: {
    okText: 'OK',
    cancelText: 'ÐÑÐ¼ÐµÐ½Ð°',
    justOkText: 'OK',
  },
  Popconfirm: {
    okText: 'OK',
    cancelText: 'ÐÑÐ¼ÐµÐ½Ð°',
  },
  Transfer: {
    notFoundContent: 'ÐÐ¸ÑÐµÐ³Ð¾ Ð½Ðµ Ð½Ð°Ð¹Ð´ÐµÐ½Ð¾',
    searchPlaceholder: 'ÐÐ²ÐµÐ´Ð¸ÑÐµ Ð½Ð°Ð·Ð²Ð°Ð½Ð¸Ðµ Ð´Ð»Ñ Ð¿Ð¾Ð¸ÑÐºÐ°',
    itemUnit: 'item',
    itemsUnit: 'items',
  },
  Select: {
    notFoundContent: 'ÐÐ¸ÑÐµÐ³Ð¾ Ð½Ðµ Ð½Ð°Ð¹Ð´ÐµÐ½Ð¾',
  },
  Upload: {
    uploading: 'ÐÐ°ÐºÐ°ÑÐ¸Ð²Ð°Ñ...',
    removeFile: 'Ð£Ð´Ð°Ð»Ð¸ÑÑ ÑÐ°Ð¹Ð»',
    uploadError: 'ÐÑÐ¸Ð±ÐºÐ° Ð¿ÑÐ¸ Ð·Ð°ÐºÐ°ÑÐºÐµ',
    previewFile: 'ÐÑÐµÐ´Ð¿ÑÐ¾ÑÐ¼Ð¾ÑÑ ÑÐ°Ð¹Ð»Ð°',
  },
};
