// This icon file is generated by build/generate.ts
// tslint:disable

import { IconDefinition } from '../types';

export const VerticalLeftOutline: IconDefinition = {
  name: 'vertical-left',
  theme: 'outline',
  icon:
    '<svg  viewBox="64 64 896 896"><path d="M762 164h-64c-4.4 0-8 3.6-8 8v688c0 4.4 3.6 8 8 8h64c4.4 0 8-3.6 8-8V172c0-4.4-3.6-8-8-8zm-508 0v72.4c0 9.5 4.2 18.4 11.4 24.5L564.6 512 265.4 763.1c-7.2 6.1-11.4 15-11.4 24.5V860c0 6.8 7.9 10.5 13.1 6.1L689 512 267.1 157.9A7.95 7.95 0 0 0 254 164z"/></svg>'
};
