// This icon file is generated by build/generate.ts
// tslint:disable

import { IconDefinition } from '../types';

export const WalletFill: IconDefinition = {
  name: 'wallet',
  theme: 'fill',
  icon:
    '<svg  viewBox="64 64 896 896"><path d="M880 112H144c-17.7 0-32 14.3-32 32v736c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V144c0-17.7-14.3-32-32-32zm-32 464H528V448h320v128zm-268-64a40 40 0 1 0 80 0 40 40 0 1 0-80 0z"/></svg>'
};
