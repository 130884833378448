// This icon file is generated by build/generate.ts
// tslint:disable

import { IconDefinition } from '../types';

export const CodeSandboxSquareFill: IconDefinition = {
  name: 'code-sandbox-square',
  theme: 'fill',
  icon:
    '<svg  viewBox="64 64 896 896"><path d="M307.9 536.7l87.6 49.9V681l96.7 55.9V524.8L307.9 418.4zM880 112H144c-17.7 0-32 14.3-32 32v736c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V144c0-17.7-14.3-32-32-32zM755.7 653.2L512 794 268.3 653.2V371.8l110-63.6-.4-.2h.2L512 231l134 77h-.2l-.3.2 110.1 63.6v281.4zm-223.9 83.7l97.3-56.2v-94.1l87-49.5V418.5L531.8 525zm-20-352L418 331l-91.1 52.6 185.2 107 185.2-106.9-91.4-52.8z"/></svg>'
};
