// This icon file is generated by build/generate.ts
// tslint:disable

import { IconDefinition } from '../types';

export const ToTopOutline: IconDefinition = {
  name: 'to-top',
  theme: 'outline',
  icon:
    '<svg  viewBox="64 64 896 896"><path d="M885 780H165c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h720c4.4 0 8-3.6 8-8v-60c0-4.4-3.6-8-8-8zM400 325.7h73.9V664c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V325.7H624c6.7 0 10.4-7.7 6.3-12.9L518.3 171a8 8 0 0 0-12.6 0l-112 141.7c-4.1 5.3-.4 13 6.3 13z"/></svg>'
};
