export default {
  // Options.jsx
  items_per_page: '/ strana',
  jump_to: 'PÅejÃ­t',
  jump_to_confirm: 'potvrdit',
  page: '',

  // Pagination.jsx
  prev_page: 'PÅedchozÃ­ strana',
  next_page: 'NÃ¡sledujÃ­cÃ­ strana',
  prev_5: 'PÅedchozÃ­ch 5 stran',
  next_5: 'NÃ¡sledujÃ­cÃ­ch 5 stran',
  prev_3: 'PÅedchozÃ­ 3 strany',
  next_3: 'NÃ¡sledujÃ­cÃ­ 3 strany',
};
