import CalendarLocale from '../calendar/zh_CN';
import TimePickerLocale from '../time-picker/zh_CN';

const locale = {
  lang: {
    placeholder: 'è¯·éæ©æ¥æ',
    rangePlaceholder: ['å¼å§æ¥æ', 'ç»ææ¥æ'],
    ...CalendarLocale,
  },
  timePickerLocale: {
    ...TimePickerLocale,
  },
};

// should add whitespace between char in Button
locale.lang.ok = 'ç¡® å®';

// All settings at:
// https://github.com/ant-design/ant-design/blob/master/components/date-picker/locale/example.json

export default locale;
