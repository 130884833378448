// This icon file is generated by build/generate.ts
// tslint:disable

import { IconDefinition } from '../types';

export const DatabaseTwoTone: IconDefinition = {
  name: 'database',
  theme: 'twotone',
  icon:
    '<svg viewBox="64 64 896 896"><path fill="#D8D8D8" d="M232 616h560V408H232v208zm112-144c22.1 0 40 17.9 40 40s-17.9 40-40 40-40-17.9-40-40 17.9-40 40-40zM232 888h560V680H232v208zm112-144c22.1 0 40 17.9 40 40s-17.9 40-40 40-40-17.9-40-40 17.9-40 40-40zM232 344h560V136H232v208zm112-144c22.1 0 40 17.9 40 40s-17.9 40-40 40-40-17.9-40-40 17.9-40 40-40z"/><path d="M304 512a40 40 0 1 0 80 0 40 40 0 1 0-80 0zM304 784a40 40 0 1 0 80 0 40 40 0 1 0-80 0zM304 240a40 40 0 1 0 80 0 40 40 0 1 0-80 0z"/><path d="M832 64H192c-17.7 0-32 14.3-32 32v832c0 17.7 14.3 32 32 32h640c17.7 0 32-14.3 32-32V96c0-17.7-14.3-32-32-32zm-40 824H232V680h560v208zm0-272H232V408h560v208zm0-272H232V136h560v208z"/></svg>'
};
