export default {
  // Options.jsx
  items_per_page: '/ pÃ¡gina',
  jump_to: 'Saltar',
  jump_to_confirm: 'confirmar',
  page: '',

  // Pagination.jsx
  prev_page: 'PÃ¡gina Anterior',
  next_page: 'PÃ¡gina Seguinte',
  prev_5: 'Recuar 5 PÃ¡ginas',
  next_5: 'AvanÃ§ar 5 PÃ¡ginas',
  prev_3: 'Recuar 3 PÃ¡ginas',
  next_3: 'AvanÃ§ar 3 PÃ¡ginas',
};
