// This icon file is generated by build/generate.ts
// tslint:disable

import { IconDefinition } from '../types';

export const MinusSquareOutline: IconDefinition = {
  name: 'minus-square',
  theme: 'outline',
  icon:
    '<svg  viewBox="64 64 896 896"><path d="M328 544h368c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8H328c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8z"/><path d="M880 112H144c-17.7 0-32 14.3-32 32v736c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V144c0-17.7-14.3-32-32-32zm-40 728H184V184h656v656z"/></svg>'
};
