/**
 * Created by Andrey Gayvoronsky on 13/04/16.
 */

import CalendarLocale from '../calendar/ru_RU';
import TimePickerLocale from '../time-picker/ru_RU';

const locale = {
  lang: {
    placeholder: 'ÐÑÐ±ÐµÑÐ¸ÑÐµ Ð´Ð°ÑÑ',
    rangePlaceholder: ['ÐÐ°ÑÐ°Ð»ÑÐ½Ð°Ñ Ð´Ð°ÑÐ°', 'ÐÐ¾Ð½ÐµÑÐ½Ð°Ñ Ð´Ð°ÑÐ°'],
    ...CalendarLocale,
  },
  timePickerLocale: {
    ...TimePickerLocale,
  },
};

// All settings at:
// https://github.com/ant-design/ant-design/blob/master/components/date-picker/locale/example.json

export default locale;
