export default {
  today: 'Hoje',
  now: 'Agora',
  backToToday: 'Hoje',
  ok: 'Ok',
  clear: 'Limpar',
  month: 'MÃªs',
  year: 'Ano',
  timeSelect: 'Selecionar hora',
  dateSelect: 'Selecionar data',
  monthSelect: 'Selecionar mÃªs',
  yearSelect: 'Selecionar ano',
  decadeSelect: 'Selecionar dÃ©cada',
  yearFormat: 'YYYY',
  dateFormat: 'D/M/YYYY',
  dayFormat: 'D',
  dateTimeFormat: 'D/M/YYYY HH:mm:ss',
  monthBeforeYear: true,
  previousMonth: 'MÃªs anterior (PageUp)',
  nextMonth: 'MÃªs seguinte (PageDown)',
  previousYear: 'Ano anterior (Control + left)',
  nextYear: 'Ano seguinte (Control + right)',
  previousDecade: 'DÃ©cada anterior',
  nextDecade: 'DÃ©cada seguinte',
  previousCentury: 'SÃ©culo anterior',
  nextCentury: 'SÃ©culo seguinte',
};
