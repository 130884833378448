// This icon file is generated by build/generate.ts
// tslint:disable

import { IconDefinition } from '../types';

export const SwapRightOutline: IconDefinition = {
  name: 'swap-right',
  theme: 'outline',
  icon:
    '<svg viewBox="0 0 1024 1024"><path d="M873.1 596.2l-164-208A32 32 0 0 0 684 376h-64.8c-6.7 0-10.4 7.7-6.3 13l144.3 183H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h695.9c26.8 0 41.7-30.8 25.2-51.8z"/></svg>'
};
