import Calendar from './calendar/sk_SK';
import DatePicker from './date-picker/sk_SK';
import Pagination from './pagination/sk_SK';
import TimePicker from './time-picker/sk_SK';

export default {
  locale: 'sk',
  Pagination,
  DatePicker,
  TimePicker,
  Calendar,
  Table: {
    filterTitle: 'Filter',
    filterConfirm: 'OK',
    filterReset: 'ObnoviÅ¥',
    emptyText: 'Å½iadne dÃ¡ta',
    selectAll: 'VybraÅ¥ vÅ¡etko',
    selectInvert: 'VybraÅ¥ opaÄnÃ©',
  },
  Modal: {
    okText: 'OK',
    cancelText: 'ZruÅ¡iÅ¥',
    justOkText: 'OK',
  },
  Popconfirm: {
    okText: 'OK',
    cancelText: 'ZruÅ¡iÅ¥',
  },
  Transfer: {
    notFoundContent: 'NenÃ¡jdenÃ©',
    searchPlaceholder: 'VyhÄ¾adÃ¡vanie',
    itemUnit: 'poloÅ¾ka',
    itemsUnit: 'poloÅ¾iek',
  },
  Select: {
    notFoundContent: 'NenÃ¡jdenÃ©',
  },
  Upload: {
    uploading: 'NahrÃ¡vanie...',
    removeFile: 'OdstrÃ¡niÅ¥ sÃºbor',
    uploadError: 'Chyba pri nahrÃ¡vanÃ­',
    previewFile: 'ZobraziÅ¥ sÃºbor',
  },
};
