export default {
  today: 'Ð¡ÐµÐ³Ð¾Ð´Ð½Ñ',
  now: 'Ð¡ÐµÐ¹ÑÐ°Ñ',
  backToToday: 'Ð¢ÐµÐºÑÑÐ°Ñ Ð´Ð°ÑÐ°',
  ok: 'Ok',
  clear: 'ÐÑÐ¸ÑÑÐ¸ÑÑ',
  month: 'ÐÐµÑÑÑ',
  year: 'ÐÐ¾Ð´',
  timeSelect: 'ÐÑÐ±ÑÐ°ÑÑ Ð²ÑÐµÐ¼Ñ',
  dateSelect: 'ÐÑÐ±ÑÐ°ÑÑ Ð´Ð°ÑÑ',
  monthSelect: 'ÐÑÐ±ÑÐ°ÑÑ Ð¼ÐµÑÑÑ',
  yearSelect: 'ÐÑÐ±ÑÐ°ÑÑ Ð³Ð¾Ð´',
  decadeSelect: 'ÐÑÐ±ÑÐ°ÑÑ Ð´ÐµÑÑÑÐ¸Ð»ÐµÑÐ¸Ðµ',
  yearFormat: 'YYYY',
  dateFormat: 'D-M-YYYY',
  dayFormat: 'D',
  dateTimeFormat: 'D-M-YYYY HH:mm:ss',
  monthBeforeYear: true,
  previousMonth: 'ÐÑÐµÐ´ÑÐ´ÑÑÐ¸Ð¹ Ð¼ÐµÑÑÑ (PageUp)',
  nextMonth: 'Ð¡Ð»ÐµÐ´ÑÑÑÐ¸Ð¹ Ð¼ÐµÑÑÑ (PageDown)',
  previousYear: 'ÐÑÐµÐ´ÑÐ´ÑÑÐ¸Ð¹ Ð³Ð¾Ð´ (Control + left)',
  nextYear: 'Ð¡Ð»ÐµÐ´ÑÑÑÐ¸Ð¹ Ð³Ð¾Ð´ (Control + right)',
  previousDecade: 'ÐÑÐµÐ´ÑÐ´ÑÑÐµÐµ Ð´ÐµÑÑÑÐ¸Ð»ÐµÑÐ¸Ðµ',
  nextDecade: 'Ð¡Ð»ÐµÐ´ÑÑÐµÐµ Ð´ÐµÑÑÑÐ¸Ð»ÐµÑÐ¸Ðµ',
  previousCentury: 'ÐÑÐµÐ´ÑÐ´ÑÑÐ¸Ð¹ Ð²ÐµÐº',
  nextCentury: 'Ð¡Ð»ÐµÐ´ÑÑÑÐ¸Ð¹ Ð²ÐµÐº',
};
