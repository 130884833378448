import { Component } from '@angular/core';

@Component({
  selector   : 'nz-form-split',
  templateUrl: './nz-form-split.component.html',
  host       : {
    '[class.ant-form-split]': 'true'
  }
})
export class NzFormSplitComponent {
}
