export default {
  // Options.jsx
  items_per_page: '/ trang',
  jump_to: 'Äáº¿n',
  jump_to_confirm: 'xÃ¡c nháº­n',
  page: '',

  // Pagination.jsx
  prev_page: 'Trang TrÆ°á»c',
  next_page: 'Trang Káº¿',
  prev_5: 'Vá» 5 Trang TrÆ°á»c',
  next_5: 'Äáº¿n 5 Trang Káº¿',
  prev_3: 'Vá» 3 Trang TrÆ°á»c',
  next_3: 'Äáº¿n 3 Trang Káº¿',
};
