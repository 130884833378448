// This icon file is generated by build/generate.ts
// tslint:disable

import { IconDefinition } from '../types';

export const VerticalAlignBottomOutline: IconDefinition = {
  name: 'vertical-align-bottom',
  theme: 'outline',
  icon:
    '<svg  viewBox="64 64 896 896"><path d="M859.9 780H164.1c-4.5 0-8.1 3.6-8.1 8v60c0 4.4 3.6 8 8.1 8h695.8c4.5 0 8.1-3.6 8.1-8v-60c0-4.4-3.6-8-8.1-8zM505.7 669a8 8 0 0 0 12.6 0l112-141.7c4.1-5.2.4-12.9-6.3-12.9h-74.1V176c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v338.3H400c-6.7 0-10.4 7.7-6.3 12.9l112 141.8z"/></svg>'
};
