export default {
  today: 'Avui',
  now: 'Ara',
  backToToday: 'Tornar a avui',
  ok: 'Acceptar',
  clear: 'Netejar',
  month: 'Mes',
  year: 'Any',
  timeSelect: 'Seleccionar hora',
  dateSelect: 'Seleccionar data',
  monthSelect: 'Escollir un mes',
  yearSelect: 'Escollir un any',
  decadeSelect: 'Escollir una dÃ¨cada',
  yearFormat: 'YYYY',
  dateFormat: 'D/M/YYYY',
  dayFormat: 'D',
  dateTimeFormat: 'D/M/YYYY HH:mm:ss',
  monthBeforeYear: true,
  previousMonth: 'Mes anterior (PageUp)',
  nextMonth: 'Mes segÃ¼ent (PageDown)',
  previousYear: 'Any anterior (Control + left)',
  nextYear: 'Mes segÃ¼ent (Control + right)',
  previousDecade: 'DÃ¨cada anterior',
  nextDecade: 'DÃ¨cada segÃ¼ent',
  previousCentury: 'Segle anterior',
  nextCentury: 'Segle segÃ¼ent',
};
