// This icon file is generated by build/generate.ts
// tslint:disable

import { IconDefinition } from '../types';

export const MailFill: IconDefinition = {
  name: 'mail',
  theme: 'fill',
  icon:
    '<svg  viewBox="64 64 896 896"><path d="M928 160H96c-17.7 0-32 14.3-32 32v640c0 17.7 14.3 32 32 32h832c17.7 0 32-14.3 32-32V192c0-17.7-14.3-32-32-32zm-80.8 108.9L531.7 514.4c-7.8 6.1-18.7 6.1-26.5 0L189.6 268.9A7.2 7.2 0 0 1 194 256h648.8a7.2 7.2 0 0 1 4.4 12.9z"/></svg>'
};
