import Calendar from './calendar/fi_FI';
import DatePicker from './date-picker/fi_FI';
import Pagination from './pagination/fi_FI';
import TimePicker from './time-picker/fi_FI';

export default {
  locale: 'fi',
  Pagination,
  DatePicker,
  TimePicker,
  Calendar,
  Table: {
    filterTitle: 'Suodatus valikko',
    filterConfirm: 'OK',
    filterReset: 'TyhjennÃ¤',
    emptyText: 'Ei kohteita',
    selectAll: 'Valitse kaikki',
    selectInvert: 'Valitse pÃ¤invastoin',
  },
  Modal: {
    okText: 'OK',
    cancelText: 'Peruuta',
    justOkText: 'OK',
  },
  Popconfirm: {
    okText: 'OK',
    cancelText: 'Peruuta',
  },
  Transfer: {
    notFoundContent: 'Ei lÃ¶ytynyt',
    searchPlaceholder: 'Etsi tÃ¤Ã¤ltÃ¤',
    itemUnit: 'kohde',
    itemsUnit: 'kohdetta',
  },
  Select: {
    notFoundContent: 'Ei lÃ¶ytynyt',
  },
  Upload: {
    uploading: 'LÃ¤hetetÃ¤Ã¤n...',
    removeFile: 'Poista tiedosto',
    uploadError: 'Virhe lÃ¤hetyksessÃ¤',
    previewFile: 'Esikatsele tiedostoa',
  },
};
