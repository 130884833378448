// This icon file is generated by build/generate.ts
// tslint:disable

import { IconDefinition } from '../types';

export const CreditCardFill: IconDefinition = {
  name: 'credit-card',
  theme: 'fill',
  icon:
    '<svg  viewBox="64 64 896 896"><path d="M928 160H96c-17.7 0-32 14.3-32 32v160h896V192c0-17.7-14.3-32-32-32zM64 832c0 17.7 14.3 32 32 32h832c17.7 0 32-14.3 32-32V440H64v392zm579-184c0-4.4 3.6-8 8-8h165c4.4 0 8 3.6 8 8v72c0 4.4-3.6 8-8 8H651c-4.4 0-8-3.6-8-8v-72z"/></svg>'
};
