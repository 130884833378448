import CalendarLocale from '../calendar/fr_FR';
import TimePickerLocale from '../time-picker/fr_FR';

// Merge into a locale object
const locale = {
  lang: {
    placeholder: 'SÃ©lectionner une date',
    rangePlaceholder: ['Date de dÃ©but', 'Date de fin'],
    ...CalendarLocale,
  },
  timePickerLocale: {
    ...TimePickerLocale,
  },
};

// All settings at:
// https://github.com/ant-design/ant-design/issues/424

export default locale;
