export default {
  // Options.jsx
  items_per_page: '/ ÏÎµÎ»Î¯Î´Î±',
  jump_to: 'ÎÎµÏÎ¬Î²Î±ÏÎ·',
  jump_to_confirm: 'ÎµÏÎ¹Î²ÎµÎ²Î±Î¹ÏÎ½Ï',
  page: '',

  // Pagination.jsx
  prev_page: 'Î ÏÎ¿Î·Î³Î¿ÏÎ¼ÎµÎ½Î· Î£ÎµÎ»Î¯Î´Î±',
  next_page: 'ÎÏÏÎ¼ÎµÎ½Î· Î£ÎµÎ»Î¯Î´Î±',
  prev_5: 'Î ÏÎ¿Î·Î³Î¿ÏÎ¼ÎµÎ½ÎµÏ 5 Î£ÎµÎ»Î¯Î´ÎµÏ',
  next_5: 'ÎÏÏÎ¼ÎµÎ½ÎµÏ 5 ÏÎµÎ»Î¯Î´ÎµÏ',
  prev_3: 'Î ÏÎ¿Î·Î³Î¿ÏÎ¼ÎµÎ½ÎµÏ 3 Î£ÎµÎ»Î¯Î´ÎµÏ',
  next_3: 'ÎÏÏÎ¼ÎµÎ½ÎµÏ 3 Î£ÎµÎ»Î¯Î´ÎµÏ',
};
