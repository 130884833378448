export default {
  today: 'ä»å¤©',
  now: 'æ­¤å»',
  backToToday: 'è¿åä»å¤©',
  ok: 'ç¡®å®',
  timeSelect: 'éæ©æ¶é´',
  dateSelect: 'éæ©æ¥æ',
  clear: 'æ¸é¤',
  month: 'æ',
  year: 'å¹´',
  previousMonth: 'ä¸ä¸ªæ (ç¿»é¡µä¸é®)',
  nextMonth: 'ä¸ä¸ªæ (ç¿»é¡µä¸é®)',
  monthSelect: 'éæ©æä»½',
  yearSelect: 'éæ©å¹´ä»½',
  decadeSelect: 'éæ©å¹´ä»£',
  yearFormat: 'YYYYå¹´',
  dayFormat: 'Dæ¥',
  dateFormat: 'YYYYå¹´MæDæ¥',
  dateTimeFormat: 'YYYYå¹´MæDæ¥ HHæ¶mmåssç§',
  previousYear: 'ä¸ä¸å¹´ (Controlé®å å·¦æ¹åé®)',
  nextYear: 'ä¸ä¸å¹´ (Controlé®å å³æ¹åé®)',
  previousDecade: 'ä¸ä¸å¹´ä»£',
  nextDecade: 'ä¸ä¸å¹´ä»£',
  previousCentury: 'ä¸ä¸ä¸çºª',
  nextCentury: 'ä¸ä¸ä¸çºª',
};
