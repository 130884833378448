import Calendar from './calendar/is_IS';
import DatePicker from './date-picker/is_IS';
import Pagination from './pagination/is_IS';
import TimePicker from './time-picker/is_IS';

export default {
  locale: 'is',
  Pagination,
  DatePicker,
  TimePicker,
  Calendar,
  Table: {
    filterTitle: 'Afmarkanir',
    filterConfirm: 'StaÃ°festa',
    filterReset: 'NÃºllstilla',
    emptyText: 'Engin gÃ¶gn',
    selectAll: 'Velja allt',
    selectInvert: 'ViÃ°snÃºa vali',
  },
  Modal: {
    okText: 'Ãfram',
    cancelText: 'HÃ¦tta viÃ°',
    justOkText: 'Ã lagi',
  },
  Popconfirm: {
    okText: 'Ãfram',
    cancelText: 'HÃ¦tta viÃ°',
  },
  Transfer: {
    notFoundContent: 'Engar fÃ¦rslur',
    searchPlaceholder: 'Leita hÃ©r',
    itemUnit: 'fÃ¦rsla',
    itemsUnit: 'fÃ¦rslur',
  },
  Select: {
    notFoundContent: 'Ekkert finnst',
  },
  Upload: {
    uploading: 'HleÃ° upp...',
    removeFile: 'FjarlÃ¦gja skrÃ¡',
    uploadError: 'Villa viÃ° aÃ° hlaÃ°a upp',
    previewFile: 'ForskoÃ°a skrÃ¡',
  },
};
