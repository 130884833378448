// This icon file is generated by build/generate.ts
// tslint:disable

import { IconDefinition } from '../types';

export const FolderFill: IconDefinition = {
  name: 'folder',
  theme: 'fill',
  icon:
    '<svg  viewBox="64 64 896 896"><path d="M880 298.4H521L403.7 186.2a8.15 8.15 0 0 0-5.5-2.2H144c-17.7 0-32 14.3-32 32v592c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V330.4c0-17.7-14.3-32-32-32z"/></svg>'
};
