// This icon file is generated by build/generate.ts
// tslint:disable

import { IconDefinition } from '../types';

export const EnterOutline: IconDefinition = {
  name: 'enter',
  theme: 'outline',
  icon:
    '<svg  viewBox="64 64 896 896"><path d="M864 170h-60c-4.4 0-8 3.6-8 8v518H310v-73c0-6.7-7.8-10.5-13-6.3l-141.9 112a8 8 0 0 0 0 12.6l141.9 112c5.3 4.2 13 .4 13-6.3v-75h498c35.3 0 64-28.7 64-64V178c0-4.4-3.6-8-8-8z"/></svg>'
};
