// This icon file is generated by build/generate.ts
// tslint:disable

import { IconDefinition } from '../types';

export const BulbFill: IconDefinition = {
  name: 'bulb',
  theme: 'fill',
  icon:
    '<svg  viewBox="64 64 896 896"><path d="M348 676.1C250 619.4 184 513.4 184 392c0-181.1 146.9-328 328-328s328 146.9 328 328c0 121.4-66 227.4-164 284.1V792c0 17.7-14.3 32-32 32H380c-17.7 0-32-14.3-32-32V676.1zM392 888h240c4.4 0 8 3.6 8 8v32c0 17.7-14.3 32-32 32H416c-17.7 0-32-14.3-32-32v-32c0-4.4 3.6-8 8-8z"/></svg>'
};
