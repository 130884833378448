export default {
  // Options.jsx
  items_per_page: '/ Ø§ÙØµÙØ­Ø©',
  jump_to: 'Ø§ÙØ°ÙØ§Ø¨ Ø¥ÙÙ',
  jump_to_confirm: 'ØªØ£ÙÙØ¯',
  page: '',

  // Pagination.jsx
  prev_page: 'Ø§ÙØµÙØ­Ø© Ø§ÙØ³Ø§Ø¨ÙØ©',
  next_page: 'Ø§ÙØµÙØ­Ø© Ø§ÙØªØ§ÙÙØ©',
  prev_5: 'Ø®ÙØ³ ØµÙØ­Ø§Øª Ø³Ø§Ø¨ÙØ©',
  next_5: 'Ø®ÙØ³ ØµÙØ­Ø§Øª ØªØ§ÙÙØ©',
  prev_3: 'Ø«ÙØ§Ø« ØµÙØ­Ø§Øª Ø³Ø§Ø¨ÙØ©',
  next_3: 'Ø«ÙØ§Ø« ØµÙØ­Ø§Øª ØªØ§ÙÙØ©',
};
