// This icon file is generated by build/generate.ts
// tslint:disable

import { IconDefinition } from '../types';

export const BellFill: IconDefinition = {
  name: 'bell',
  theme: 'fill',
  icon:
    '<svg  viewBox="64 64 896 896"><path d="M816 768h-24V428c0-141.1-104.3-257.8-240-277.2V112c0-22.1-17.9-40-40-40s-40 17.9-40 40v38.8C336.3 170.2 232 286.9 232 428v340h-24c-17.7 0-32 14.3-32 32v32c0 4.4 3.6 8 8 8h216c0 61.8 50.2 112 112 112s112-50.2 112-112h216c4.4 0 8-3.6 8-8v-32c0-17.7-14.3-32-32-32zM512 888c-26.5 0-48-21.5-48-48h96c0 26.5-21.5 48-48 48z"/></svg>'
};
