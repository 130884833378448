// This icon file is generated by build/generate.ts
// tslint:disable

import { IconDefinition } from '../types';

export const TabletOutline: IconDefinition = {
  name: 'tablet',
  theme: 'outline',
  icon:
    '<svg  viewBox="64 64 896 896"><path d="M800 64H224c-35.3 0-64 28.7-64 64v768c0 35.3 28.7 64 64 64h576c35.3 0 64-28.7 64-64V128c0-35.3-28.7-64-64-64zm-8 824H232V136h560v752zM472 784a40 40 0 1 0 80 0 40 40 0 1 0-80 0z"/></svg>'
};
