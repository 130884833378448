export default {
  today: 'I dag',
  now: 'Nu',
  backToToday: 'Till idag',
  ok: 'Ok',
  clear: 'Avbryt',
  month: 'MÃ¥nad',
  year: 'Ãr',
  timeSelect: 'VÃ¤lj tidpunkt',
  dateSelect: 'VÃ¤lj datum',
  monthSelect: 'VÃ¤lj mÃ¥nad',
  yearSelect: 'VÃ¤lj Ã¥r',
  decadeSelect: 'VÃ¤lj Ã¥rtionde',
  yearFormat: 'YYYY',
  dateFormat: 'YYYY-MM-DD',
  dayFormat: 'D',
  dateTimeFormat: 'YYYY-MM-DD H:mm:ss',
  monthBeforeYear: true,
  previousMonth: 'FÃ¶rra mÃ¥naden (PageUp)',
  nextMonth: 'NÃ¤sta mÃ¥nad (PageDown)',
  previousYear: 'FÃ¶reg Ã¥r (Control + left)',
  nextYear: 'NÃ¤sta Ã¥r (Control + right)',
  previousDecade: 'FÃ¶reg Ã¥rtionde',
  nextDecade: 'NÃ¤sta Ã¥rtionde',
  previousCentury: 'FÃ¶reg Ã¥rhundrade',
  nextCentury: 'NÃ¤sta Ã¥rhundrade',
};
