import CalendarLocale from '../calendar/sk_SK';
import TimePickerLocale from '../time-picker/sk_SK';

// ç»ä¸åå¹¶ä¸ºå®æ´ç Locale
const locale = {
  lang: {
    placeholder: 'VybraÅ¥ dÃ¡tum',
    rangePlaceholder: ['Od', 'Do'],
    ...CalendarLocale,
  },
  timePickerLocale: {
    ...TimePickerLocale,
  },
};

// All settings at:
// https://github.com/ant-design/ant-design/blob/master/components/date-picker/locale/example.json

export default locale;
