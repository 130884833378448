// This icon file is generated by build/generate.ts
// tslint:disable

import { IconDefinition } from '../types';

export const SlackOutline: IconDefinition = {
  name: 'slack',
  theme: 'outline',
  icon:
    '<svg  viewBox="64 64 896 896"><path d="M925.5 541.9a71.49 71.49 0 0 0-90.1-45.9l-80 26L703 360.9l80-26a71.49 71.49 0 0 0 45.9-90.1 71.49 71.49 0 0 0-90.1-45.9l-80 26-26.2-80.6a71.49 71.49 0 1 0-136 44.2l26.2 80.6-161.1 52.4-26.2-80.6a71.49 71.49 0 1 0-136 44.2l26.2 80.6-81.1 26.4a71.49 71.49 0 0 0-45.9 90.1 71.49 71.49 0 0 0 90.1 45.9l81.1-26.4 52.4 161.1-81.3 26.3a71.49 71.49 0 0 0-45.9 90.1 71.49 71.49 0 0 0 90.1 45.9l81.1-26.4 26.2 80.6a71.49 71.49 0 0 0 90.1 45.9 71.49 71.49 0 0 0 45.9-90.1l-26.2-80.6 161.1-52.4 26.2 80.6a71.49 71.49 0 0 0 90.1 45.9 71.49 71.49 0 0 0 45.9-90.1l-26-80.5 80-26c37.5-12.1 58.1-52.5 45.9-90.1zm-467.3 76.7l-52.4-161.1L567 405.1l52.4 161.1-161.2 52.4z"/></svg>'
};
