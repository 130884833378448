import Calendar from './calendar/ja_JP';
import DatePicker from './date-picker/ja_JP';
import Pagination from './pagination/ja_JP';
import TimePicker from './time-picker/ja_JP';

export default {
  locale: 'ja',
  Pagination,
  DatePicker,
  TimePicker,
  Calendar,
  Table: {
    filterTitle: 'ã¡ãã¥ã¼ããã£ã«ã¿ã¼',
    filterConfirm: 'OK',
    filterReset: 'ãªã»ãã',
    emptyText: 'ãã¼ã¿ãããã¾ãã',
    selectAll: 'ãã¹ã¦ãé¸æ',
    selectInvert: 'é¸æãåè»¢',
  },
  Modal: {
    okText: 'OK',
    cancelText: 'ã­ã£ã³ã»ã«',
    justOkText: 'OK',
  },
  Popconfirm: {
    okText: 'OK',
    cancelText: 'ã­ã£ã³ã»ã«',
  },
  Transfer: {
    notFoundContent: 'çµæã¯ããã¾ãã',
    searchPlaceholder: 'ãããæ¤ç´¢',
    itemUnit: 'ã¢ã¤ãã ',
    itemsUnit: 'ã¢ã¤ãã ',
  },
  Select: {
    notFoundContent: 'çµæã¯ããã¾ãã',
  },
  Upload: {
    uploading: 'ã¢ããã­ã¼ãä¸­...',
    removeFile: 'ãã¡ã¤ã«ãåé¤',
    uploadError: 'ã¢ããã­ã¼ãã¨ã©ã¼',
    previewFile: 'ãã¡ã¤ã«ããã¬ãã¥ã¼',
  },
};
