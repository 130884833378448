import Calendar from './calendar/en_US';
import DatePicker from './date-picker/tr_TR';
import Pagination from './pagination/en_US';
import TimePicker from './time-picker/tr_TR';

export default {
  locale: 'tr',
  Pagination,
  DatePicker,
  TimePicker,
  Calendar,
  Table: {
    filterTitle: 'MenÃ¼ Filtrele',
    filterConfirm: 'Tamam',
    filterReset: 'SÄ±fÄ±rla',
    emptyText: 'Veri Yok',
    selectAll: 'Hepsini SeÃ§',
    selectInvert: 'Tersini SeÃ§',
  },
  Modal: {
    okText: 'Tamam',
    cancelText: 'Ä°ptal',
    justOkText: 'Tamam',
  },
  Popconfirm: {
    okText: 'Tamam',
    cancelText: 'Ä°ptal',
  },
  Transfer: {
    notFoundContent: 'BulunamadÄ±',
    searchPlaceholder: 'Arama',
    itemUnit: 'ÃÄe',
    itemsUnit: 'ÃÄeler',
  },
  Select: {
    notFoundContent: 'BulunamadÄ±',
  },
  Upload: {
    uploading: 'YÃ¼kleniyor...',
    removeFile: `DosyayÄ± kaldÄ±r`,
    uploadError: 'YÃ¼kleme HatasÄ±',
    previewFile: `DosyayÄ± Ãnizle`,
  },
};
