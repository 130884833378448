import CalendarLocale from '../calendar/bg_BG';
import TimePickerLocale from '../time-picker/bg_BG';

// Merge into a locale object
const locale = {
  lang: {
    placeholder: 'ÐÐ·Ð±Ð¾Ñ Ð½Ð° Ð´Ð°ÑÐ°',
    rangePlaceholder: ['ÐÐ°ÑÐ°Ð»Ð½Ð°', 'ÐÑÐ°Ð¹Ð½Ð°'],
    ...CalendarLocale,
  },
  timePickerLocale: {
    ...TimePickerLocale,
  },
};

// All settings at:
// https://github.com/ant-design/ant-design/blob/master/components/date-picker/locale/example.json

export default locale;
