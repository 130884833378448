export default {
  // Options.jsx
  items_per_page: '/ sida',
  jump_to: 'GÃ¥ till',
  jump_to_confirm: 'bekrÃ¤fta',
  page: '',

  // Pagination.jsx
  prev_page: 'FÃ¶reg sida',
  next_page: 'NÃ¤sta sida',
  prev_5: 'FÃ¶reg 5 sidor',
  next_5: 'NÃ¤sta 5 sidor',
  prev_3: 'FÃ¶reg 3 sidor',
  next_3: 'NÃ¤sta 3 sidor',
};
