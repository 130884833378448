// This icon file is generated by build/generate.ts
// tslint:disable

import { IconDefinition } from '../types';

export const DashOutline: IconDefinition = {
  name: 'dash',
  theme: 'outline',
  icon:
    '<svg  viewBox="64 64 896 896"><path d="M112 476h160v72H112zm320 0h160v72H432zm320 0h160v72H752z"/></svg>'
};
