import Calendar from './calendar/fa_IR';
import DatePicker from './date-picker/fa_IR';
import Pagination from './pagination/fa_IR';
import TimePicker from './time-picker/fa_IR';

export default {
  locale: 'fa',
  Pagination,
  DatePicker,
  TimePicker,
  Calendar,
  Table: {
    filterTitle: 'ÙÙÙÛ ÙÛÙØªØ±',
    filterConfirm: 'ØªØ§ÛÛØ¯',
    filterReset: 'Ù¾Ø§Ú© Ú©Ø±Ø¯Ù',
    emptyText: 'Ø¯Ø§Ø¯ÙâØ§Û ÙÙØ¬ÙØ¯ ÙÛØ³Øª',
    selectAll: 'Ø§ÙØªØ®Ø§Ø¨ ØµÙØ­ÙâÛ Ú©ÙÙÙÛ',
    selectInvert: 'ÙØ¹Ú©ÙØ³ Ú©Ø±Ø¯Ù Ø§ÙØªØ®Ø§Ø¨âÙØ§ Ø¯Ø± ØµÙØ­Ù Û Ú©ÙÙÙÛ',
  },
  Modal: {
    okText: 'ØªØ§ÛÛØ¯',
    cancelText: 'ÙØºÙ',
    justOkText: 'ØªØ§ÛÛØ¯',
  },
  Popconfirm: {
    okText: 'ØªØ§ÛÛØ¯',
    cancelText: 'ÙØºÙ',
  },
  Transfer: {
    notFoundContent: 'Ø¯Ø§Ø¯ÙâØ§Û ÙÙØ¬ÙØ¯ ÙÛØ³Øª',
    searchPlaceholder: 'Ø¬Ø³ØªØ¬Ù',
    itemUnit: '',
    itemsUnit: '',
  },
  Select: {
    notFoundContent: 'Ø¯Ø§Ø¯ÙâØ§Û ÙÙØ¬ÙØ¯ ÙÛØ³Øª',
  },
  Upload: {
    uploading: 'Ø¯Ø± Ø­Ø§Ù Ø¢Ù¾ÙÙØ¯...',
    removeFile: 'Ø­Ø°Ù ÙØ§ÛÙ',
    uploadError: 'Ø®Ø·Ø§ Ø¯Ø± Ø¢Ù¾ÙÙØ¯',
    previewFile: 'ÙØ´Ø§ÙØ¯ÙâÛ ÙØ§ÛÙ',
  },
};
