// This icon file is generated by build/generate.ts
// tslint:disable

import { IconDefinition } from '../types';

export const LeftSquareFill: IconDefinition = {
  name: 'left-square',
  theme: 'fill',
  icon:
    '<svg  viewBox="64 64 896 896"><path d="M880 112H144c-17.7 0-32 14.3-32 32v736c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V144c0-17.7-14.3-32-32-32zM624 380.9c0 10.2-4.9 19.9-13.2 25.9L465.4 512l145.4 105.2c8.3 6 13.2 15.6 13.2 25.9V690c0 6.5-7.4 10.3-12.7 6.5l-246-178a7.95 7.95 0 0 1 0-12.9l246-178c5.3-3.8 12.7 0 12.7 6.5v46.8z"/></svg>'
};
