export default {
  // Options.jsx
  items_per_page: '/ ØµÙØ­Ù',
  jump_to: 'Ø¨Ø±Ù Ø¨Ù',
  jump_to_confirm: 'ØªØ§ÛÛØ¯',
  page: '',

  // Pagination.jsx
  prev_page: 'ØµÙØ­Ù ÙØ¨ÙÛ',
  next_page: 'ØµÙØ­Ù Ø¨Ø¹Ø¯Û',
  prev_5: 'Ûµ ØµÙØ­Ù ÙØ¨ÙÛ',
  next_5: 'Ûµ ØµÙØ­Ù Ø¨Ø¹Ø¯Û',
  prev_3: 'Û³ ØµÙØ­Ù ÙØ¨ÙÛ',
  next_3: 'Û³ ØµÙØ­Ù Ø¨Ø¹Ø¯Û',
};
