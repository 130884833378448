// This icon file is generated by build/generate.ts
// tslint:disable

import { IconDefinition } from '../types';

export const DropboxOutline: IconDefinition = {
  name: 'dropbox',
  theme: 'outline',
  icon:
    '<svg  viewBox="64 64 896 896"><path d="M64 556.9l264.2 173.5L512.5 577 246.8 412.7zm896-290.3zm0 0L696.8 95 512.5 248.5l265.2 164.2L512.5 577l184.3 153.4L960 558.8 777.7 412.7zM513 609.8L328.2 763.3l-79.4-51.5v57.8L513 928l263.7-158.4v-57.8l-78.9 51.5zM328.2 95L64 265.1l182.8 147.6 265.7-164.2zM64 556.9z"/></svg>'
};
