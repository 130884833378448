import CalendarLocale from '../calendar/zh_TW';
import TimePickerLocale from '../time-picker/zh_TW';

const locale = {
  lang: {
    placeholder: 'è«é¸ææ¥æ',
    rangePlaceholder: ['éå§æ¥æ', 'çµææ¥æ'],
    ...CalendarLocale,
  },
  timePickerLocale: {
    ...TimePickerLocale,
  },
};

locale.lang.ok = 'ç¢º å®';

// All settings at:
// https://github.com/ant-design/ant-design/blob/master/components/date-picker/locale/example.json

export default locale;
