export default {
  today: 'Ð¡ÑÐ¾Ð³Ð¾Ð´Ð½Ñ',
  now: 'ÐÐ°ÑÐ°Ð·',
  backToToday: 'ÐÐ¾ÑÐ¾ÑÐ½Ð° Ð´Ð°ÑÐ°',
  ok: 'Ok',
  clear: 'ÐÑÐ¸ÑÑÐ¸ÑÐ¸',
  month: 'ÐÑÑÑÑÑ',
  year: 'Ð ÑÐº',
  timeSelect: 'ÐÐ±ÑÐ°ÑÐ¸ ÑÐ°Ñ',
  dateSelect: 'ÐÐ±ÑÐ°ÑÐ¸ Ð´Ð°ÑÑ',
  monthSelect: 'ÐÐ±ÑÐ°ÑÐ¸ Ð¼ÑÑÑÑÑ',
  yearSelect: 'ÐÐ±ÑÐ°ÑÐ¸ ÑÑÐº',
  decadeSelect: 'ÐÐ±ÑÐ°ÑÐ¸ Ð´ÐµÑÑÑÐ¸ÑÑÑÑÑ',
  yearFormat: 'YYYY',
  dateFormat: 'D-M-YYYY',
  dayFormat: 'D',
  dateTimeFormat: 'D-M-YYYY HH:mm:ss',
  monthBeforeYear: true,
  previousMonth: 'ÐÐ¾Ð¿ÐµÑÐµÐ´Ð½ÑÐ¹ Ð¼ÑÑÑÑÑ (PageUp)',
  nextMonth: 'ÐÐ°ÑÑÑÐ¿Ð½Ð¸Ð¹ Ð¼ÑÑÑÑÑ (PageDown)',
  previousYear: 'ÐÐ¾Ð¿ÐµÑÐµÐ´Ð½ÑÐ¹ ÑÑÐº (Control + left)',
  nextYear: 'ÐÐ°ÑÑÑÐ¿Ð½Ð¸Ð¹ ÑÑÐº (Control + right)',
  previousDecade: 'ÐÐ¾Ð¿ÐµÑÐµÐ´Ð½Ñ Ð´ÐµÑÑÑÐ¸ÑÑÑÑÑ',
  nextDecade: 'ÐÐ°ÑÑÑÐ¿Ð½Ðµ Ð´ÐµÑÑÑÐ¸ÑÑÑÑÑ',
  previousCentury: 'ÐÐ¾Ð¿ÐµÑÐµÐ´Ð½Ñ ÑÑÐ¾Ð»ÑÑÑÑ',
  nextCentury: 'ÐÐ°ÑÑÑÐ¿Ð½Ðµ ÑÑÐ¾Ð»ÑÑÑÑ',
};
