// This icon file is generated by build/generate.ts
// tslint:disable

import { IconDefinition } from '../types';

export const EllipsisOutline: IconDefinition = {
  name: 'ellipsis',
  theme: 'outline',
  icon:
    '<svg  viewBox="64 64 896 896"><path d="M176 511a56 56 0 1 0 112 0 56 56 0 1 0-112 0zm280 0a56 56 0 1 0 112 0 56 56 0 1 0-112 0zm280 0a56 56 0 1 0 112 0 56 56 0 1 0-112 0z"/></svg>'
};
