// This icon file is generated by build/generate.ts
// tslint:disable

import { IconDefinition } from '../types';

export const ArrowUpOutline: IconDefinition = {
  name: 'arrow-up',
  theme: 'outline',
  icon:
    '<svg  viewBox="64 64 896 896"><path d="M868 545.5L536.1 163a31.96 31.96 0 0 0-48.3 0L156 545.5a7.97 7.97 0 0 0 6 13.2h81c4.6 0 9-2 12.1-5.5L474 300.9V864c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V300.9l218.9 252.3c3 3.5 7.4 5.5 12.1 5.5h81c6.8 0 10.5-8 6-13.2z"/></svg>'
};
