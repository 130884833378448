import CalendarLocale from '../calendar/et_EE';
import TimePickerLocale from '../time-picker/et_EE';

// ç»ä¸åå¹¶ä¸ºå®æ´ç Locale
const locale = {
  lang: {
    placeholder: 'Vali kuupÃ¤ev',
    rangePlaceholder: ['Algus kuupÃ¤ev', 'LÃµpu kuupÃ¤ev'],
    ...CalendarLocale,
  },
  timePickerLocale: {
    ...TimePickerLocale,
  },
};

// All settings at:
// https://github.com/ant-design/ant-design/blob/master/components/date-picker/locale/example.json

export default locale;
