import CalendarLocale from '../calendar/sv_SE';
import TimePickerLocale from '../time-picker/sv_SE';

const locale = {
  lang: {
    placeholder: 'VÃ¤lj datum',
    rangePlaceholder: ['Startdatum', 'Slutdatum'],
    ...CalendarLocale,
  },
  timePickerLocale: {
    ...TimePickerLocale,
  },
};

// All settings at:
// https://github.com/ant-design/ant-design/blob/master/components/date-picker/locale/example.json

export default locale;
