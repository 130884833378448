export default {
  // Options.jsx
  items_per_page: '/ à¸«à¸à¹à¸²',
  jump_to: 'à¹à¸à¸¢à¸±à¸',
  jump_to_confirm: 'à¸¢à¸·à¸à¸¢à¸±à¸',
  page: '',

  // Pagination.jsx
  prev_page: 'à¸«à¸à¹à¸²à¸à¹à¸­à¸à¸«à¸à¹à¸²',
  next_page: 'à¸«à¸à¹à¸²à¸à¸±à¸à¹à¸',
  prev_5: 'à¸¢à¹à¸­à¸à¸à¸¥à¸±à¸ 5 à¸«à¸à¹à¸²',
  next_5: 'à¸à¸±à¸à¹à¸ 5 à¸«à¸à¹à¸²',
  prev_3: 'à¸¢à¹à¸­à¸à¸à¸¥à¸±à¸ 3 à¸«à¸à¹à¸²',
  next_3: 'à¸à¸±à¸à¹à¸ 3 à¸«à¸à¹à¸²',
};
