// This icon file is generated by build/generate.ts
// tslint:disable

import { IconDefinition } from '../types';

export const BackwardOutline: IconDefinition = {
  name: 'backward',
  theme: 'outline',
  icon:
    '<svg viewBox="0 0 1024 1024"><path d="M485.6 249.9L198.2 498c-8.3 7.1-8.3 20.8 0 27.9l287.4 248.2c10.7 9.2 26.4.9 26.4-14V263.8c0-14.8-15.7-23.2-26.4-13.9zm320 0L518.2 498a18.6 18.6 0 0 0-6.2 14c0 5.2 2.1 10.4 6.2 14l287.4 248.2c10.7 9.2 26.4.9 26.4-14V263.8c0-14.8-15.7-23.2-26.4-13.9z"/></svg>'
};
