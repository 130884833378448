import Calendar from './calendar/fr_BE';
import DatePicker from './date-picker/fr_BE';
import Pagination from './pagination/fr_BE';
import TimePicker from './time-picker/fr_BE';

export default {
  locale: 'fr',
  Pagination,
  DatePicker,
  TimePicker,
  Calendar,
  Table: {
    filterTitle: 'Filtrer',
    filterConfirm: 'OK',
    filterReset: 'RÃ©initialiser',
    emptyText: 'Aucune donnÃ©e',
  },
  Modal: {
    okText: 'OK',
    cancelText: 'Annuler',
    justOkText: 'OK',
  },
  Popconfirm: {
    okText: 'OK',
    cancelText: 'Annuler',
  },
  Transfer: {
    notFoundContent: 'Pas de rÃ©sultat',
    searchPlaceholder: 'Recherche',
    itemUnit: 'Ã©lÃ©ment',
    itemsUnit: 'Ã©lÃ©ments',
  },
  Select: {
    notFoundContent: 'Pas de rÃ©sultat',
  },
};
