// This icon file is generated by build/generate.ts
// tslint:disable

import { IconDefinition } from '../types';

export const Html5Fill: IconDefinition = {
  name: 'html5',
  theme: 'fill',
  icon:
    '<svg  viewBox="64 64 896 896"><path d="M145.2 96l66 746.6L512 928l299.6-85.4L878.9 96H145.2zm595 177.1l-4.8 47.2-1.7 19.5H382.3l8.2 94.2h335.1l-3.3 24.3-21.2 242.2-1.7 16.2-187 51.6v.3h-1.2l-.3.1v-.1h-.1l-188.6-52L310.8 572h91.1l6.5 73.2 102.4 27.7h.4l102-27.6 11.4-118.6H510.9v-.1H306l-22.8-253.5-1.7-24.3h460.3l-1.6 24.3z"/></svg>'
};
