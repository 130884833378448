export default {
  // Options.jsx
  items_per_page: 'æ¡/é¡µ',
  jump_to: 'è·³è³',
  jump_to_confirm: 'ç¡®å®',
  page: 'é¡µ',

  // Pagination.jsx
  prev_page: 'ä¸ä¸é¡µ',
  next_page: 'ä¸ä¸é¡µ',
  prev_5: 'åå 5 é¡µ',
  next_5: 'åå 5 é¡µ',
  prev_3: 'åå 3 é¡µ',
  next_3: 'åå 3 é¡µ',
};
