import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NzSkeletonComponent } from './nz-skeleton.component';

@NgModule({
    declarations: [ NzSkeletonComponent ],
    imports: [ CommonModule ],
    exports: [ NzSkeletonComponent ]
})
export class NzSkeletonModule {}
