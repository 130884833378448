import CalendarLocale from '../calendar/th_TH';
import TimePickerLocale from '../time-picker/th_TH';

// Merge into a locale object
const locale = {
  lang            : {
    placeholder     : 'à¹à¸¥à¸·à¸­à¸à¸§à¸±à¸à¸à¸µà¹',
    rangePlaceholder: [ 'à¸§à¸±à¸à¹à¸£à¸´à¹à¸¡à¸à¹à¸', 'à¸§à¸±à¸à¸ªà¸´à¹à¸à¸ªà¸¸à¸' ],
    ...CalendarLocale
  },
  timePickerLocale: { ...TimePickerLocale }
};

// All settings at:
// https://github.com/ant-design/ant-design/blob/master/components/date-picker/locale/example.json

export default locale;
