// This icon file is generated by build/generate.ts
// tslint:disable

import { IconDefinition } from '../types';

export const HomeFill: IconDefinition = {
  name: 'home',
  theme: 'fill',
  icon:
    '<svg  viewBox="64 64 896 896"><path d="M946.5 505L534.6 93.4a31.93 31.93 0 0 0-45.2 0L77.5 505c-12 12-18.8 28.3-18.8 45.3 0 35.3 28.7 64 64 64h43.4V908c0 17.7 14.3 32 32 32H448V716h112v224h265.9c17.7 0 32-14.3 32-32V614.3h43.4c17 0 33.3-6.7 45.3-18.8 24.9-25 24.9-65.5-.1-90.5z"/></svg>'
};
