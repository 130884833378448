import Calendar from './calendar/el_GR';
import DatePicker from './date-picker/el_GR';
import Pagination from './pagination/el_GR';
import TimePicker from './time-picker/el_GR';

export default {
  locale: 'el',
  Pagination,
  DatePicker,
  TimePicker,
  Calendar,
  Table: {
    filterTitle: 'ÎÎµÎ½Î¿Ï ÏÎ¯Î»ÏÏÏÎ½',
    filterConfirm: 'ÎÎ',
    filterReset: 'ÎÏÎ±Î½Î±ÏÎ¿ÏÎ¬',
    emptyText: 'ÎÎµÎ½ ÏÏÎ¬ÏÏÎ¿ÏÎ½ Î´ÎµÎ´Î¿Î¼Î­Î½Î±',
    selectAll: 'ÎÏÎ¹Î»Î¿Î³Î® ÏÏÎ­ÏÎ¿ÏÏÎ±Ï ÏÎµÎ»Î¯Î´Î±Ï',
    selectInvert: 'ÎÎ½ÏÎ¹ÏÏÏÎ¿ÏÎ® ÏÏÎ­ÏÎ¿ÏÏÎ±Ï ÏÎµÎ»Î¯Î´Î±Ï',
  },
  Modal: {
    okText: 'ÎÎ',
    cancelText: 'ÎÎºÏÏÎ¿',
    justOkText: 'ÎÎ',
  },
  Popconfirm: {
    okText: 'ÎÎ',
    cancelText: 'ÎÎºÏÏÎ¿',
  },
  Transfer: {
    notFoundContent: 'ÎÎµÎ½ Î²ÏÎ­Î¸Î·ÎºÎµ',
    searchPlaceholder: 'ÎÎ½Î±Î¶Î®ÏÎ·ÏÎ·',
    itemUnit: 'Î±Î½ÏÎ¹ÎºÎµÎ¯Î¼ÎµÎ½Î¿',
    itemsUnit: 'Î±Î½ÏÎ¹ÎºÎµÎ¯Î¼ÎµÎ½Î±',
  },
  Select: {
    notFoundContent: 'ÎÎµÎ½ Î²ÏÎ­Î¸Î·ÎºÎµ',
  },
  Upload: {
    uploading: 'ÎÎµÏÎ±ÏÏÏÏÏÏÎ·...',
    removeFile: 'ÎÏÎ±Î¯ÏÎµÏÎ· Î±ÏÏÎµÎ¯Î¿Ï',
    uploadError: 'Î£ÏÎ¬Î»Î¼Î± Î¼ÎµÏÎ±ÏÏÏÏÏÏÎ·Ï',
    previewFile: 'Î ÏÎ¿ÎµÏÎ¹ÏÎºÏÏÎ·ÏÎ· Î±ÏÏÎµÎ¯Î¿Ï',
  },
};
