// This icon file is generated by build/generate.ts
// tslint:disable

import { IconDefinition } from '../types';

export const InfoOutline: IconDefinition = {
  name: 'info',
  theme: 'outline',
  icon:
    '<svg  viewBox="64 64 896 896"><path d="M448 224a64 64 0 1 0 128 0 64 64 0 1 0-128 0zm96 168h-64c-4.4 0-8 3.6-8 8v464c0 4.4 3.6 8 8 8h64c4.4 0 8-3.6 8-8V400c0-4.4-3.6-8-8-8z"/></svg>'
};
