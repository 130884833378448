// This icon file is generated by build/generate.ts
// tslint:disable

import { IconDefinition } from '../types';

export const SlackSquareFill: IconDefinition = {
  name: 'slack-square',
  theme: 'fill',
  icon:
    '<svg  viewBox="64 64 896 896"><path d="M893.1 397.6c-85.8-286-209.5-352.5-495.5-266.7S45.1 340.4 130.9 626.4s209.5 352.5 495.5 266.7 352.5-209.5 266.7-495.5zm-150 189.9l-50.3 16.3 16.5 50.6c7.7 23.6-5.3 49-28.9 56.7-23.6 7.7-49-5.3-56.7-28.9l-16.5-50.6L506 664.5l16.5 50.6c7.7 23.6-5.3 49-28.9 56.7-23.6 7.7-49-5.3-56.7-28.9l-16.5-50.6-51 16.6c-23.6 7.7-49-5.3-56.7-28.9s5.3-49 28.9-56.7l51-16.6-32.9-101.3-51 16.6c-23.6 7.7-49-5.3-56.7-28.9-7.7-23.6 5.3-49 28.9-56.7l51-16.6-16.5-50.6c-7.7-23.6 5.3-49 28.9-56.7 23.6-7.7 49 5.3 56.7 28.9l16.5 50.6 101.3-32.9-16.5-50.6c-7.7-23.6 5.3-49 28.9-56.7s49 5.3 56.7 28.9l16.5 50.6 50.3-16.3c23.6-7.7 49 5.3 56.7 28.9 7.7 23.6-5.3 49-28.9 56.7L632.1 417 665 518.3l50.3-16.3c23.6-7.7 49 5.3 56.7 28.9 7.7 23.6-5.3 48.9-28.9 56.6zM445.26 477.67l101.3-32.9 32.9 101.29-101.29 32.9z"/></svg>'
};
