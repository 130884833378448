import Calendar from './calendar/zh_CN';
import DatePicker from './date-picker/zh_CN';
import Pagination from './pagination/zh_CN';
import TimePicker from './time-picker/zh_CN';

export default {
  locale: 'zh-cn',
  Pagination,
  DatePicker,
  TimePicker,
  Calendar,
  Table: {
    filterTitle: 'ç­é',
    filterConfirm: 'ç¡®å®',
    filterReset: 'éç½®',
    emptyText: 'ææ æ°æ®',
    selectAll: 'å¨éå½é¡µ',
    selectInvert: 'åéå½é¡µ',
  },
  Modal: {
    okText: 'ç¡®å®',
    cancelText: 'åæ¶',
    justOkText: 'ç¥éäº',
  },
  Popconfirm: {
    cancelText: 'åæ¶',
    okText: 'ç¡®å®',
  },
  Transfer: {
    notFoundContent: 'æ å¹éç»æ',
    searchPlaceholder: 'è¯·è¾å¥æç´¢åå®¹',
    itemUnit: 'é¡¹',
    itemsUnit: 'é¡¹',
  },
  Select: {
    notFoundContent: 'æ å¹éç»æ',
  },
  Upload: {
    uploading: 'æä»¶ä¸ä¼ ä¸­',
    removeFile: 'å é¤æä»¶',
    uploadError: 'ä¸ä¼ éè¯¯',
    previewFile: 'é¢è§æä»¶',
  },
};
