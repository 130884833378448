export default {
  // Options.jsx
  items_per_page: '/ pÃ¡gina',
  jump_to: 'Ir a',
  jump_to_confirm: 'confirmar',
  page: '',

  // Pagination.jsx
  prev_page: 'PÃ¡gina anterior',
  next_page: 'PÃ¡gina siguiente',
  prev_5: '5 pÃ¡ginas previas',
  next_5: '5 pÃ¡ginas siguientes',
  prev_3: '3 pÃ¡ginas previas',
  next_3: '3 pÃ¡ginas siguientes',
};
