export default {
  today: 'Ø§ÙÙÙÙ',
  now: 'Ø§ÙØ£Ù',
  backToToday: 'Ø§ÙØ¹ÙØ¯Ø© Ø¥ÙÙ Ø§ÙÙÙÙ',
  ok: 'ØªØ£ÙÙØ¯',
  clear: 'ÙØ³Ø­',
  month: 'Ø§ÙØ´ÙØ±',
  year: 'Ø§ÙØ³ÙØ©',
  timeSelect: 'Ø§Ø®ØªÙØ§Ø± Ø§ÙÙÙØª',
  dateSelect: 'Ø§Ø®ØªÙØ§Ø± Ø§ÙØªØ§Ø±ÙØ®',
  monthSelect: 'Ø§Ø®ØªÙØ§Ø± Ø§ÙØ´ÙØ±',
  yearSelect: 'Ø§Ø®ØªÙØ§Ø± Ø§ÙØ³ÙØ©',
  decadeSelect: 'Ø§Ø®ØªÙØ§Ø± Ø§ÙØ¹ÙØ¯',
  yearFormat: 'YYYY',
  dateFormat: 'M/D/YYYY',
  dayFormat: 'D',
  dateTimeFormat: 'M/D/YYYY HH:mm:ss',
  monthBeforeYear: true,
  previousMonth: 'Ø§ÙØ´ÙØ± Ø§ÙØ³Ø§Ø¨Ù (PageUp)',
  nextMonth: 'Ø§ÙØ´ÙØ± Ø§ÙØªØ§ÙÙ(PageDown)',
  previousYear: 'Ø§ÙØ¹Ø§Ù Ø§ÙØ³Ø§Ø¨Ù (Control + left)',
  nextYear: 'Ø§ÙØ¹Ø§Ù Ø§ÙØªØ§ÙÙ (Control + right)',
  previousDecade: 'Ø§ÙØ¹ÙØ¯ Ø§ÙØ³Ø§Ø¨Ù',
  nextDecade: 'Ø§ÙØ¹ÙØ¯ Ø§ÙØªØ§ÙÙ',
  previousCentury: 'Ø§ÙÙØ±Ù Ø§ÙØ³Ø§Ø¨Ù',
  nextCentury: 'Ø§ÙÙØ±Ù Ø§ÙØªØ§ÙÙ',
};
