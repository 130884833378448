export default {
  today: 'Hoje',
  now: 'Agora',
  backToToday: 'Voltar para hoje',
  ok: 'Ok',
  clear: 'Limpar',
  month: 'MÃªs',
  year: 'Ano',
  timeSelect: 'Selecionar tempo',
  dateSelect: 'Selecionar data',
  monthSelect: 'Escolher mÃªs',
  yearSelect: 'Escolher ano',
  decadeSelect: 'Escolher dÃ©cada',
  yearFormat: 'YYYY',
  dateFormat: 'D/M/YYYY',
  dayFormat: 'D',
  dateTimeFormat: 'D/M/YYYY HH:mm:ss',
  monthBeforeYear: false,
  previousMonth: 'MÃªs anterior (PageUp)',
  nextMonth: 'PrÃ³ximo mÃªs (PageDown)',
  previousYear: 'Ano anterior (Control + esquerda)',
  nextYear: 'PrÃ³ximo ano (Control + direita)',
  previousDecade: 'DÃ©cada anterior',
  nextDecade: 'PrÃ³xima dÃ©cada',
  previousCentury: 'SÃ©culo anterior',
  nextCentury: 'PrÃ³ximo sÃ©culo',
};
