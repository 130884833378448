// This icon file is generated by build/generate.ts
// tslint:disable

import { IconDefinition } from '../types';

export const PlusOutline: IconDefinition = {
  name: 'plus',
  theme: 'outline',
  icon:
    '<svg  viewBox="64 64 896 896"><path d="M848 474H550V152h-76v322H176c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h298v322h76V550h298c4.4 0 8-3.6 8-8v-60c0-4.4-3.6-8-8-8z"/></svg>'
};
