// This icon file is generated by build/generate.ts
// tslint:disable

import { IconDefinition } from '../types';

export const TrademarkCircleTwoTone: IconDefinition = {
  name: 'trademark-circle',
  theme: 'twotone',
  icon:
    '<svg viewBox="64 64 896 896"><path fill="#333" d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"/><path fill="#E6E6E6" d="M512 140c-205.4 0-372 166.6-372 372s166.6 372 372 372 372-166.6 372-372-166.6-372-372-372zm170.7 584.2c-1.1.5-2.3.8-3.5.8h-62c-3.1 0-5.9-1.8-7.2-4.6l-74.6-159.2h-88.7V717c0 4.4-3.6 8-8 8H384c-4.4 0-8-3.6-8-8V307c0-4.4 3.6-8 8-8h155.6c98.8 0 144.2 59.9 144.2 131.1 0 70.2-43.6 106.4-78.4 119.2l80.8 164.2c2.1 3.9.4 8.7-3.5 10.7z"/><path fill="#E6E6E6" d="M529.9 357h-83.4v148H528c53 0 82.8-25.6 82.8-72.4 0-50.3-32.9-75.6-80.9-75.6z"/><path fill="#333" d="M605.4 549.3c34.8-12.8 78.4-49 78.4-119.2 0-71.2-45.4-131.1-144.2-131.1H384c-4.4 0-8 3.6-8 8v410c0 4.4 3.6 8 8 8h54.7c4.4 0 8-3.6 8-8V561.2h88.7L610 720.4c1.3 2.8 4.1 4.6 7.2 4.6h62c1.2 0 2.4-.3 3.5-.8 3.9-2 5.6-6.8 3.5-10.7l-80.8-164.2zM528 505h-81.5V357h83.4c48 0 80.9 25.3 80.9 75.6 0 46.8-29.8 72.4-82.8 72.4z"/></svg>'
};
