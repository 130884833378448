import Calendar from './calendar/zh_TW';
import DatePicker from './date-picker/zh_TW';
import Pagination from './pagination/zh_TW';
import TimePicker from './time-picker/zh_TW';

export default {
  locale: 'zh-tw',
  Pagination,
  DatePicker,
  TimePicker,
  Calendar,
  Table: {
    filterTitle: 'ç¯©é¸å¨',
    filterConfirm: 'ç¢º å®',
    filterReset: 'é ç½®',
    emptyText: 'ç®åå°ç¡è³æ',
    selectAll: 'å¨é¨é¸å',
    selectInvert: 'ååé¸å',
  },
  Modal: {
    okText: 'ç¢º å®',
    cancelText: 'å æ¶',
    justOkText: 'OK',
  },
  Popconfirm: {
    okText: 'ç¢º å®',
    cancelText: 'å æ¶',
  },
  Transfer: {
    notFoundContent: 'æ¥ç¡æ­¤è³æ',
    searchPlaceholder: 'æå°è³æ',
    itemUnit: 'é ç®',
    itemsUnit: 'é ç®',
  },
  Select: {
    notFoundContent: 'æ¥ç¡æ­¤è³æ',
  },
  Upload: {
    uploading: 'æ­£å¨ä¸å³...',
    removeFile: 'åªé¤æªæ¡',
    uploadError: 'ä¸å³å¤±æ',
    previewFile: 'æªæ¡é è¦½',
  },
};
