export default {
  today: 'Ã dag',
  now: 'NÃºna',
  backToToday: 'Til baka til dagsins Ã­ dag',
  ok: 'Ã lagi',
  clear: 'Hreinsa',
  month: 'MÃ¡nuÃ°ur',
  year: 'Ãr',
  timeSelect: 'Velja tÃ­ma',
  dateSelect: 'Velja dag',
  monthSelect: 'Velja mÃ¡nuÃ°',
  yearSelect: 'Velja Ã¡r',
  decadeSelect: 'Velja Ã¡ratug',
  yearFormat: 'YYYY',
  dateFormat: 'D/M/YYYY',
  dayFormat: 'D',
  dateTimeFormat: 'D/M/YYYY HH:mm:ss',
  monthBeforeYear: true,
  previousMonth: 'Fyrri mÃ¡nuÃ°ur (PageUp)',
  nextMonth: 'NÃ¦sti mÃ¡nuÃ°ur (PageDown)',
  previousYear: 'Fyrra Ã¡r (Control + left)',
  nextYear: 'NÃ¦sta Ã¡r (Control + right)',
  previousDecade: 'Fyrri Ã¡ratugur',
  nextDecade: 'NÃ¦sti Ã¡ratugur',
  previousCentury: 'Fyrri Ã¶ld',
  nextCentury: 'NÃ¦sta Ã¶ld',
};
