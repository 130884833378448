import CalendarLocale from '../calendar/fa_IR';
import TimePickerLocale from '../time-picker/fa_IR';

// Merge into a locale object
const locale = {
  lang: {
    placeholder: 'Ø§ÙØªØ®Ø§Ø¨ ØªØ§Ø±ÛØ®',
    rangePlaceholder: ['ØªØ§Ø±ÛØ® Ø´Ø±ÙØ¹', 'ØªØ§Ø±ÛØ® Ù¾Ø§ÛØ§Ù'],
    ...CalendarLocale,
  },
  timePickerLocale: {
    ...TimePickerLocale,
  },
};

// All settings at:
// https://github.com/ant-design/ant-design/blob/master/components/date-picker/locale/example.json

export default locale;
