export default {
  today: 'Ø§ÙØ±ÙØ²',
  now: 'Ø§Ú©ÙÙÙ',
  backToToday: 'Ø¨Ø§Ø²Ú¯Ø´Øª Ø¨Ù Ø±ÙØ²',
  ok: 'Ø¨Ø§Ø´Ù',
  clear: 'Ù¾Ø§Ú© Ú©Ø±Ø¯Ù',
  month: 'ÙØ§Ù',
  year: 'Ø³Ø§Ù',
  timeSelect: 'Ø§ÙØªØ®Ø§Ø¨ Ø²ÙØ§Ù',
  dateSelect: 'Ø§ÙØªØ®Ø§Ø¨ ØªØ§Ø±ÛØ®',
  monthSelect: 'ÛÚ© ÙØ§Ù Ø±Ø§ Ø§ÙØªØ®Ø§Ø¨ Ú©ÙÛØ¯',
  yearSelect: 'ÛÚ© Ø³Ø§Ù Ø±Ø§ Ø§ÙØªØ®Ø§Ø¨ Ú©ÙÛØ¯',
  decadeSelect: 'ÛÚ© Ø¯ÙÙ Ø±Ø§ Ø§ÙØªØ®Ø§Ø¨ Ú©ÙÛØ¯',
  yearFormat: 'YYYY',
  dateFormat: 'M/D/YYYY',
  dayFormat: 'D',
  dateTimeFormat: 'M/D/YYYY HH:mm:ss',
  monthBeforeYear: true,
  previousMonth: 'ÙØ§Ù ÙØ¨Ù (PageUp)',
  nextMonth: 'ÙØ§Ù Ø¨Ø¹Ø¯ (PageDown)',
  previousYear: 'Ø³Ø§Ù ÙØ¨Ù (Control + left)',
  nextYear: 'Ø³Ø§Ù Ø¨Ø¹Ø¯ (Control + right)',
  previousDecade: 'Ø¯ÙÙ ÙØ¨Ù',
  nextDecade: 'Ø¯ÙÙ Ø¨Ø¹Ø¯',
  previousCentury: 'ÙØ±Ù ÙØ¨Ù',
  nextCentury: 'ÙØ±Ù Ø¨Ø¹Ø¯',
};
