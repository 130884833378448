// This icon file is generated by build/generate.ts
// tslint:disable

import { IconDefinition } from '../types';

export const ThunderboltOutline: IconDefinition = {
  name: 'thunderbolt',
  theme: 'outline',
  icon:
    '<svg  viewBox="64 64 896 896"><path d="M848 359.3H627.7L825.8 109c4.1-5.3.4-13-6.3-13H436c-2.8 0-5.5 1.5-6.9 4L170 547.5c-3.1 5.3.7 12 6.9 12h174.4l-89.4 357.6c-1.9 7.8 7.5 13.3 13.3 7.7L853.5 373c5.2-4.9 1.7-13.7-5.5-13.7zM378.2 732.5l60.3-241H281.1l189.6-327.4h224.6L487 427.4h211L378.2 732.5z"/></svg>'
};
