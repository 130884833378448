import CalendarLocale from '../calendar/ar_EG';
import TimePickerLocale from '../time-picker/ar_EG';

// Merge into a locale object
const locale = {
  lang: {
    placeholder: 'Ø§Ø®ØªÙØ§Ø± Ø§ÙØªØ§Ø±ÙØ®',
    rangePlaceholder: ['Ø§ÙØ¨Ø¯Ø§ÙØ©', 'Ø§ÙÙÙØ§ÙØ©'],
    ...CalendarLocale,
  },
  timePickerLocale: {
    ...TimePickerLocale,
  },
};

// All settings at:
// https://github.com/ant-design/ant-design/blob/master/components/date-picker/locale/example.json

export default locale;
