// This icon file is generated by build/generate.ts
// tslint:disable

import { IconDefinition } from '../types';

export const FallOutline: IconDefinition = {
  name: 'fall',
  theme: 'outline',
  icon:
    '<svg  viewBox="64 64 896 896"><path d="M925.9 804l-24-199.2c-.8-6.6-8.9-9.4-13.6-4.7L829 659.5 557.7 388.3c-6.3-6.2-16.4-6.2-22.6 0L433.3 490 156.6 213.3a8.03 8.03 0 0 0-11.3 0l-45 45.2a8.03 8.03 0 0 0 0 11.3L422 591.7c6.2 6.3 16.4 6.3 22.6 0L546.4 490l226.1 226-59.3 59.3a8.01 8.01 0 0 0 4.7 13.6l199.2 24c5.1.7 9.5-3.7 8.8-8.9z"/></svg>'
};
