export default {
  // Options.jsx
  items_per_page: '/ pÃ¡ginas',
  jump_to: 'VÃ¡ atÃ©',
  jump_to_confirm: 'confirme',
  page: '',

  // Pagination.jsx
  prev_page: 'PÃ¡gina anterior',
  next_page: 'PrÃ³xima pÃ¡gina',
  prev_5: '5 pÃ¡ginas anteriores',
  next_5: '5 prÃ³ximas pÃ¡ginas',
  prev_3: '3 pÃ¡ginas anteriores',
  next_3: '3 prÃ³ximas pÃ¡ginas',
};
