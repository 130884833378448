import Calendar from './calendar/cs_CZ';
import DatePicker from './date-picker/cs_CZ';
import Pagination from './pagination/cs_CZ';
import TimePicker from './time-picker/cs_CZ';

export default {
  locale: 'cs',
  Pagination,
  DatePicker,
  TimePicker,
  Calendar,
  Table: {
    filterTitle: 'Filtr',
    filterConfirm: 'Potvrdit',
    filterReset: 'Obnovit',
    emptyText: 'Å½Ã¡dnÃ¡ data',
  },
  Modal: {
    okText: 'Ok',
    cancelText: 'Storno',
    justOkText: 'Ok',
  },
  Popconfirm: {
    okText: 'Ok',
    cancelText: 'Storno',
  },
  Transfer: {
    notFoundContent: 'Nenalezeno',
    searchPlaceholder: 'VyhledÃ¡vÃ¡nÃ­',
    itemUnit: 'poloÅ¾ka',
    itemsUnit: 'poloÅ¾ek',
  },
  Select: {
    notFoundContent: 'Nenalezeno',
  },
  Upload: {
    uploading: 'NahrÃ¡vÃ¡nÃ­...',
    removeFile: 'Odstranit soubor',
    uploadError: 'Chyba pÅi nahrÃ¡vÃ¡nÃ­',
    previewFile: 'Zobrazit soubor',
  },
};
