import Calendar from './calendar/th_TH';
import DatePicker from './date-picker/th_TH';
import Pagination from './pagination/th_TH';
import TimePicker from './time-picker/th_TH';

export default {
  locale: 'th',
  Pagination,
  DatePicker,
  TimePicker,
  Calendar,
  Table: {
    filterTitle: 'à¸à¸±à¸§à¸à¸£à¸­à¸',
    filterConfirm: 'à¸¢à¸·à¸à¸¢à¸±à¸',
    filterReset: 'à¸£à¸µà¹à¸à¹à¸',
    emptyText: 'à¹à¸¡à¹à¸¡à¸µà¸à¹à¸­à¸¡à¸¹à¸¥',
    selectAll: 'à¹à¸¥à¸·à¸­à¸à¸à¸±à¹à¸à¸«à¸¡à¸à¹à¸à¸«à¸à¹à¸²à¸à¸µà¹',
    selectInvert: 'à¹à¸¥à¸·à¸­à¸à¸ªà¸à¸²à¸à¸°à¸à¸£à¸à¸à¸±à¸à¸à¹à¸²à¸¡',
  },
  Modal: {
    okText: 'à¸à¸à¸¥à¸',
    cancelText: 'à¸¢à¸à¹à¸¥à¸´à¸',
    justOkText: 'à¸à¸à¸¥à¸',
  },
  Popconfirm: {
    okText: 'à¸à¸à¸¥à¸',
    cancelText: 'à¸¢à¸à¹à¸¥à¸´à¸',
  },
  Transfer: {
    notFoundContent: 'à¹à¸¡à¹à¸à¸à¸à¹à¸­à¸¡à¸¹à¸¥',
    searchPlaceholder: 'à¸à¹à¸à¸«à¸²',
    itemUnit: 'à¸à¸´à¹à¸',
    itemsUnit: 'à¸à¸´à¹à¸',
  },
  Select: {
    notFoundContent: 'à¹à¸¡à¹à¸à¸à¸à¹à¸­à¸¡à¸¹à¸¥',
  },
  Upload: {
    uploading: 'à¸à¸³à¸¥à¸±à¸à¸­à¸±à¸à¹à¸«à¸¥à¸...',
    removeFile: 'à¸¥à¸à¹à¸à¸¥à¹',
    uploadError: 'à¹à¸à¸´à¸à¸à¹à¸­à¸à¸´à¸à¸à¸¥à¸²à¸à¹à¸à¸à¸²à¸£à¸­à¸±à¸à¹à¸«à¸¥à¸',
    previewFile: 'à¸à¸¹à¸à¸±à¸§à¸­à¸¢à¹à¸²à¸à¹à¸à¸¥à¹',
  },
};
