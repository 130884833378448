import CalendarLocale from '../calendar/en_US';
import TimePickerLocale from '../time-picker/en_US';

// Merge into a locale object
const locale = {
  lang: {
    placeholder: 'Chá»n thá»i Äiá»m',
    rangePlaceholder: ['NgÃ y báº¯t Äáº§u', 'NgÃ y káº¿t thÃºc'],
    ...CalendarLocale,
  },
  timePickerLocale: {
    ...TimePickerLocale,
  },
};

// All settings at:
// https://github.com/ant-design/ant-design/blob/master/components/date-picker/locale/example.json

export default locale;
