import Calendar from './calendar/vi_VN';
import DatePicker from './date-picker/vi_VN';
import Pagination from './pagination/vi_VN';
import TimePicker from './time-picker/vi_VN';

export default {
  locale: 'vi',
  Pagination,
  DatePicker,
  TimePicker,
  Calendar,
  Table: {
    filterTitle: 'Bá» ',
    filterConfirm: 'OK',
    filterReset: 'Táº¡o Láº¡i',
    emptyText: 'Trá»ng',
    selectAll: 'Chá»n Táº¥t Cáº£',
    selectInvert: 'Chá»n NgÆ°á»£c Láº¡i',
  },
  Modal: {
    okText: 'OK',
    cancelText: 'Huá»·',
    justOkText: 'OK',
  },
  Popconfirm: {
    okText: 'OK',
    cancelText: 'Huá»·',
  },
  Transfer: {
    notFoundContent: 'KhÃ´ng TÃ¬m Tháº¥y',
    searchPlaceholder: 'TÃ¬m á» ÄÃ¢y',
    itemUnit: 'má»¥c',
    itemsUnit: 'má»¥c',
  },
  Select: {
    notFoundContent: 'KhÃ´ng TÃ¬m Tháº¥y',
  },
  Upload: {
    uploading: 'Äang táº£i lÃªn...',
    removeFile: 'Gá»¡ bá» táº­p tin',
    uploadError: 'Lá»i táº£i lÃªn',
    previewFile: 'Xem thá»­ táº­p tin',
  },
};
