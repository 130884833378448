export default {
  today: 'Heute',
  now: 'Jetzt',
  backToToday: 'ZurÃ¼ck zu Heute',
  ok: 'OK',
  clear: 'ZurÃ¼cksetzen',
  month: 'Monat',
  year: 'Jahr',
  timeSelect: 'Zeit wÃ¤hlen',
  dateSelect: 'Datum wÃ¤hlen',
  monthSelect: 'WÃ¤hle einen Monat',
  yearSelect: 'WÃ¤hle ein Jahr',
  decadeSelect: 'WÃ¤hle ein Jahrzehnt',
  yearFormat: 'YYYY',
  dateFormat: 'D.M.YYYY',
  dayFormat: 'D',
  dateTimeFormat: 'D.M.YYYY HH:mm:ss',
  monthBeforeYear: true,
  previousMonth: 'Vorheriger Monat (PageUp)',
  nextMonth: 'NÃ¤chster Monat (PageDown)',
  previousYear: 'Vorheriges Jahr (Ctrl + left)',
  nextYear: 'NÃ¤chstes Jahr (Ctrl + right)',
  previousDecade: 'Vorheriges Jahrzehnt',
  nextDecade: 'NÃ¤chstes Jahrzehnt',
  previousCentury: 'Vorheriges Jahrhundert',
  nextCentury: 'NÃ¤chstes Jahrhundert',
};
