export default {
  today: 'à¸§à¸±à¸à¸à¸µà¹',
  now: 'à¸à¸­à¸à¸à¸µà¹',
  backToToday: 'à¸à¸¥à¸±à¸à¹à¸à¸¢à¸±à¸à¸§à¸±à¸à¸à¸µà¹',
  ok: 'à¸à¸à¸¥à¸',
  clear: 'à¸¥à¸à¸¥à¹à¸²à¸',
  month: 'à¹à¸à¸·à¸­à¸',
  year: 'à¸à¸µ',
  timeSelect: 'à¹à¸¥à¸·à¸­à¸à¹à¸§à¸¥à¸²',
  dateSelect: 'à¹à¸¥à¸·à¸­à¸à¸§à¸±à¸',
  monthSelect: 'à¹à¸¥à¸·à¸­à¸à¹à¸à¸·à¸­à¸',
  yearSelect: 'à¹à¸¥à¸·à¸­à¸à¸à¸µ',
  decadeSelect: 'à¹à¸¥à¸·à¸­à¸à¸à¸¨à¸§à¸£à¸£à¸©',
  yearFormat: 'YYYY',
  dateFormat: 'D/M/YYYY',
  dayFormat: 'D',
  dateTimeFormat: 'D/M/YYYY HH:mm:ss',
  monthBeforeYear: true,
  previousMonth: 'à¹à¸à¸·à¸­à¸à¸à¹à¸­à¸à¸«à¸à¹à¸² (PageUp)',
  nextMonth: 'à¹à¸à¸·à¸­à¸à¸à¸±à¸à¹à¸ (PageDown)',
  previousYear: 'à¸à¸µà¸à¹à¸­à¸à¸«à¸à¹à¸² (Control + left)',
  nextYear: 'à¸à¸µà¸à¸±à¸à¹à¸ (Control + right)',
  previousDecade: 'à¸à¸¨à¸§à¸£à¸£à¸©à¸à¹à¸­à¸à¸«à¸à¹à¸²',
  nextDecade: 'à¸à¸¨à¸§à¸£à¸£à¸©à¸à¸±à¸à¹à¸',
  previousCentury: 'à¸¨à¸à¸§à¸£à¸£à¸©à¸à¹à¸­à¸à¸«à¸à¹à¸²',
  nextCentury: 'à¸¨à¸à¸§à¸£à¸£à¸©à¸à¸±à¸à¹à¸',
};
