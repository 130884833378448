export default {
  today: 'ÐÐ½ÐµÑ',
  now: 'Ð¡ÐµÐ³Ð°',
  backToToday: 'ÐÑÐ¼ Ð´Ð½ÐµÑ',
  ok: 'ÐÐ¾Ð±ÑÐµ',
  clear: 'ÐÐ·ÑÐ¸ÑÑÐ²Ð°Ð½Ðµ',
  month: 'ÐÐµÑÐµÑ',
  year: 'ÐÐ¾Ð´Ð¸Ð½Ð°',
  timeSelect: 'ÐÐ·Ð±Ð¾Ñ Ð½Ð° ÑÐ°Ñ',
  dateSelect: 'ÐÐ·Ð±Ð¾Ñ Ð½Ð° Ð´Ð°ÑÐ°',
  monthSelect: 'ÐÐ·Ð±Ð¾Ñ Ð½Ð° Ð¼ÐµÑÐµÑ',
  yearSelect: 'ÐÐ·Ð±Ð¾Ñ Ð½Ð° Ð³Ð¾Ð´Ð¸Ð½Ð°',
  decadeSelect: 'ÐÐµÑÐµÑÐ¸Ð»ÐµÑÐ¸Ðµ',
  yearFormat: 'YYYY',
  dateFormat: 'D M YYYY',
  dayFormat: 'D',
  dateTimeFormat: 'D M YYYY HH:mm:ss',
  monthBeforeYear: true,
  previousMonth: 'ÐÑÐµÐ´Ð¸ÑÐµÐ½ Ð¼ÐµÑÐµÑ (PageUp)',
  nextMonth: 'Ð¡Ð»ÐµÐ´Ð²Ð°Ñ Ð¼ÐµÑÐµÑ (PageDown)',
  previousYear: 'ÐÐ¾ÑÐ»ÐµÐ´Ð½Ð° Ð³Ð¾Ð´Ð¸Ð½Ð° (Control + left)',
  nextYear: 'Ð¡Ð»ÐµÐ´Ð²Ð°ÑÐ° Ð³Ð¾Ð´Ð¸Ð½Ð° (Control + right)',
  previousDecade: 'ÐÑÐµÐ´Ð¸ÑÐ½Ð¾ Ð´ÐµÑÐµÑÐ¸Ð»ÐµÑÐ¸Ðµ',
  nextDecade: 'Ð¡Ð»ÐµÐ´Ð²Ð°ÑÐ¾ Ð´ÐµÑÐµÑÐ¸Ð»ÐµÑÐ¸Ðµ',
  previousCentury: 'ÐÐ¾ÑÐ»ÐµÐ´ÐµÐ½ Ð²ÐµÐº',
  nextCentury: 'Ð¡Ð»ÐµÐ´Ð²Ð°Ñ Ð²ÐµÐº',
};
