// This icon file is generated by build/generate.ts
// tslint:disable

import { IconDefinition } from '../types';

export const PauseOutline: IconDefinition = {
  name: 'pause',
  theme: 'outline',
  icon:
    '<svg  viewBox="64 64 896 896"><path d="M304 176h80v672h-80zm408 0h-64c-4.4 0-8 3.6-8 8v656c0 4.4 3.6 8 8 8h64c4.4 0 8-3.6 8-8V184c0-4.4-3.6-8-8-8z"/></svg>'
};
