import CalendarLocale from '../calendar/ko_KR';
import TimePickerLocale from '../time-picker/ko_KR';

// Merge into a locale object
const locale = {
  lang: {
    placeholder: 'ë ì§ ì í',
    rangePlaceholder: ['ììì¼', 'ì¢ë£ì¼'],
    ...CalendarLocale,
  },
  timePickerLocale: {
    ...TimePickerLocale,
  },
};

// All settings at:
// https://github.com/ant-design/ant-design/blob/master/components/date-picker/locale/example.json

export default locale;
