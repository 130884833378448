import CalendarLocale from '../calendar/en_US';
import TimePickerLocale from '../time-picker/tr_TR';

// Merge into a locale object
const locale = {
  lang: {
    placeholder: 'Tarih SeÃ§',
    rangePlaceholder: ['BaÅlangÄ±Ã§ Tarihi', 'BitiÅ Tarihi'],
    ...CalendarLocale,
  },
  timePickerLocale: {
    ...TimePickerLocale,
  },
};

// All settings at:
// https://github.com/ant-design/ant-design/blob/master/components/date-picker/locale/example.json

export default locale;
