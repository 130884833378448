export default {
  today: 'Aujourd\'hui',
  now: 'Maintenant',
  backToToday: 'Aujourd\'hui',
  ok: 'Ok',
  clear: 'RÃ©tablir',
  month: 'Mois',
  year: 'AnnÃ©e',
  timeSelect: 'SÃ©lectionner l\'heure',
  dateSelect: 'SÃ©lectionner l\'heure',
  monthSelect: 'Choisissez un mois',
  yearSelect: 'Choisissez une annÃ©e',
  decadeSelect: 'Choisissez une dÃ©cennie',
  yearFormat: 'YYYY',
  dateFormat: 'D/M/YYYY',
  dayFormat: 'D',
  dateTimeFormat: 'D/M/YYYY HH:mm:ss',
  monthBeforeYear: true,
  previousMonth: 'Mois prÃ©cÃ©dent (PageUp)',
  nextMonth: 'Mois suivant (PageDown)',
  previousYear: 'AnnÃ©e prÃ©cÃ©dente (Ctrl + gauche)',
  nextYear: 'AnnÃ©e prochaine (Ctrl + droite)',
  previousDecade: 'DÃ©cennie prÃ©cÃ©dente',
  nextDecade: 'DÃ©cennie suivante',
  previousCentury: 'SiÃ¨cle prÃ©cÃ©dent',
  nextCentury: 'SiÃ¨cle suivant',
};
