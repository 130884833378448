// This icon file is generated by build/generate.ts
// tslint:disable

import { IconDefinition } from '../types';

export const WindowsFill: IconDefinition = {
  name: 'windows',
  theme: 'fill',
  icon:
    '<svg  viewBox="64 64 896 896"><path d="M523.8 191.4v288.9h382V128.1zm0 642.2l382 62.2v-352h-382zM120.1 480.2H443V201.9l-322.9 53.5zm0 290.4L443 823.2V543.8H120.1z"/></svg>'
};
