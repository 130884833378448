import Calendar from './calendar/ko_KR';
import DatePicker from './date-picker/ko_KR';
import Pagination from './pagination/ko_KR';
import TimePicker from './time-picker/ko_KR';

export default {
  locale: 'ko',
  Pagination,
  DatePicker,
  TimePicker,
  Calendar,
  Table: {
    filterTitle: 'íí° ë©ë´',
    filterConfirm: 'íì¸',
    filterReset: 'ì´ê¸°í',
    emptyText: 'ë°ì´í° ìì',
    selectAll: 'ì ì²´ ì í',
    selectInvert: 'ì í í ê¸',
  },
  Modal: {
    okText: 'íì¸',
    cancelText: 'ì·¨ì',
    justOkText: 'íì¸',
  },
  Popconfirm: {
    okText: 'íì¸',
    cancelText: 'ì·¨ì',
  },
  Transfer: {
    notFoundContent: 'ë°ì´í° ìì',
    searchPlaceholder: 'ì¬ê¸°ì ê²ìíì¸ì',
    itemUnit: 'ê°',
    itemsUnit: 'ê°',
  },
  Select: {
    notFoundContent: 'ë°ì´í° ìì',
  },
  Upload: {
    uploading: 'ìë¡ë ì¤...',
    removeFile: 'íì¼ ì­ì ',
    uploadError: 'ìë¡ë ì¤í¨',
    previewFile: 'íì¼ ë¯¸ë¦¬ë³´ê¸°',
  },
};
