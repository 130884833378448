export default {
  today: 'Î£Î®Î¼ÎµÏÎ±',
  now: 'Î¤ÏÏÎ±',
  backToToday: 'Î Î¯ÏÏ ÏÏÎ· ÏÎ·Î¼ÎµÏÎ¹Î½Î® Î¼Î­ÏÎ±',
  ok: 'Ok',
  clear: 'ÎÎ±Î¸Î±ÏÎ¹ÏÎ¼ÏÏ',
  month: 'ÎÎ®Î½Î±Ï',
  year: 'ÎÏÎ¿Ï',
  timeSelect: 'ÎÏÎ¹Î»Î¿Î³Î® ÏÏÎ±Ï',
  dateSelect: 'ÎÏÎ¹Î»Î¿Î³Î® Î·Î¼ÎµÏÎ¿Î¼Î·Î½Î¯Î±Ï',
  monthSelect: 'ÎÏÎ¹Î»Î¿Î³Î® Î¼Î®Î½Î±',
  yearSelect: 'ÎÏÎ¹Î»Î¿Î³Î® Î­ÏÎ¿ÏÏ',
  decadeSelect: 'ÎÏÎ¹Î»Î¿Î³Î® Î´ÎµÎºÎ±ÎµÏÎ¯Î±Ï',
  yearFormat: 'YYYY',
  dateFormat: 'D/M/YYYY',
  dayFormat: 'D',
  dateTimeFormat: 'D/M/YYYY HH:mm:ss',
  monthBeforeYear: true,
  previousMonth: 'Î ÏÎ¿Î·Î³Î¿ÏÎ¼ÎµÎ½Î¿Ï Î¼Î®Î½Î±Ï (PageUp)',
  nextMonth: 'ÎÏÏÎ¼ÎµÎ½Î¿Ï Î¼Î®Î½Î±Ï (PageDown)',
  previousYear: 'Î ÏÎ¿Î·Î³Î¿ÏÎ¼ÎµÎ½Î¿ Î­ÏÎ¿Ï (Control + Î±ÏÎ¹ÏÏÎµÏÎ¬)',
  nextYear: 'ÎÏÏÎ¼ÎµÎ½Î¿ Î­ÏÎ¿Ï (Control + Î´ÎµÎ¾Î¹Î¬)',
  previousDecade: 'Î ÏÎ¿Î·Î³Î¿ÏÎ¼ÎµÎ½Î· Î´ÎµÎºÎ±ÎµÏÎ¯Î±',
  nextDecade: 'ÎÏÏÎ¼ÎµÎ½Î· Î´ÎµÎºÎ±ÎµÏÎ¯Î±',
  previousCentury: 'Î ÏÎ¿Î·Î³Î¿ÏÎ¼ÎµÎ½Î¿Ï Î±Î¹ÏÎ½Î±Ï',
  nextCentury: 'ÎÏÏÎ¼ÎµÎ½Î¿Ï Î±Î¹ÏÎ½Î±Ï',
};
