import Calendar from './calendar/ar_EG';
import DatePicker from './date-picker/ar_EG';
import Pagination from './pagination/ar_EG';
import TimePicker from './time-picker/ar_EG';

export default {
  locale: 'ar',
  Pagination,
  DatePicker,
  TimePicker,
  Calendar,
  Table: {
    filterTitle: 'Ø§ÙÙÙØ§ØªØ±',
    filterConfirm: 'ØªØ£ÙÙØ¯',
    filterReset: 'Ø¥Ø¹Ø§Ø¯Ø© Ø¶Ø¨Ø·',
    emptyText: 'ÙØ§ ØªÙØ¬Ø¯ Ø¨ÙØ§ÙØ§Øª',
    selectAll: 'Ø§Ø®ØªÙØ§Ø± Ø§ÙÙÙ',
    selectInvert: 'Ø¥ÙØºØ§Ø¡ Ø§ÙØ§Ø®ØªÙØ§Ø±',
  },
  Modal: {
    okText: 'ØªØ£ÙÙØ¯',
    cancelText: 'Ø¥ÙØºØ§Ø¡',
    justOkText: 'ØªØ£ÙÙØ¯',
  },
  Popconfirm: {
    okText: 'ØªØ£ÙÙØ¯',
    cancelText: 'Ø¥ÙØºØ§Ø¡',
  },
  Transfer: {
    notFoundContent: 'ÙØ§ ÙÙØ¬Ø¯ ÙØ­ØªÙÙ',
    searchPlaceholder: 'Ø§Ø¨Ø­Ø« ÙÙØ§',
    itemUnit: 'Ø¹ÙØµØ±',
    itemsUnit: 'Ø¹ÙØ§ØµØ±',
  },
  Select: {
    notFoundContent: 'ÙØ§ÙÙØ¬Ø¯ ÙØ­ØªÙÙ',
  },
  Upload: {
    uploading: 'Ø¬Ø§Ø±Ù Ø§ÙØ±ÙØ¹...',
    removeFile: 'Ø§Ø­Ø°Ù Ø§ÙÙÙÙ',
    uploadError: 'ÙØ´ÙÙØ© ÙÙ Ø§ÙØ±ÙØ¹',
    previewFile: 'Ø§Ø³ØªØ¹Ø±Ø¶ Ø§ÙÙÙÙ',
  },
};
