// This icon file is generated by build/generate.ts
// tslint:disable

import { IconDefinition } from '../types';

export const LineOutline: IconDefinition = {
  name: 'line',
  theme: 'outline',
  icon:
    '<svg  viewBox="64 64 896 896"><path d="M904 476H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8z"/></svg>'
};
