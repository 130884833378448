// This icon file is generated by build/generate.ts
// tslint:disable

import { IconDefinition } from '../types';

export const MobileTwoTone: IconDefinition = {
  name: 'mobile',
  theme: 'twotone',
  icon:
    '<svg viewBox="64 64 896 896"><path fill="#333" d="M744 64H280c-35.3 0-64 28.7-64 64v768c0 35.3 28.7 64 64 64h464c35.3 0 64-28.7 64-64V128c0-35.3-28.7-64-64-64zm-8 824H288V136h448v752z"/><path fill="#E6E6E6" d="M288 888h448V136H288v752zm224-142c22.1 0 40 17.9 40 40s-17.9 40-40 40-40-17.9-40-40 17.9-40 40-40z"/><path fill="#333" d="M472 786a40 40 0 1 0 80 0 40 40 0 1 0-80 0z"/></svg>'
};
