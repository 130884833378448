export default {
  // Options.jsx
  items_per_page: '/ ìª½',
  jump_to: 'ì´ëíê¸°',
  jump_to_confirm: 'íì¸íë¤',
  page: '',

  // Pagination.jsx
  prev_page: 'ì´ì  íì´ì§',
  next_page: 'ë¤ì íì´ì§',
  prev_5: 'ì´ì  5 íì´ì§',
  next_5: 'ë¤ì 5 íì´ì§',
  prev_3: 'ì´ì  3 íì´ì§',
  next_3: 'ë¤ì 3 íì´ì§',
};
