export default {
  // Options.jsx
  items_per_page: 'æ¢/é ',
  jump_to: 'è·³è³',
  jump_to_confirm: 'ç¢ºå®',
  page: 'é ',

  // Pagination.jsx
  prev_page: 'ä¸ä¸é ',
  next_page: 'ä¸ä¸é ',
  prev_5: 'åå 5 é ',
  next_5: 'åå¾ 5 é ',
  prev_3: 'åå 3 é ',
  next_3: 'åå¾ 3 é ',
};
