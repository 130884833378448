import CalendarLocale from '../calendar/uk_UA';
import TimePickerLocale from '../time-picker/uk_UA';

const locale = {
  lang: {
    placeholder: 'ÐÐ±ÐµÑÑÑÑ Ð´Ð°ÑÑ',
    rangePlaceholder: ['ÐÐ¾ÑÐ°ÑÐºÐ¾Ð²Ð° Ð´Ð°ÑÐ°', 'ÐÑÐ½ÑÐµÐ²Ð° Ð´Ð°ÑÐ°'],
    ...CalendarLocale,
  },
  timePickerLocale: {
    ...TimePickerLocale,
  },
};

// All settings at:
// https://github.com/ant-design/ant-design/blob/master/components/date-picker/locale/example.json

export default locale;
