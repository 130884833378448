export default {
  today: 'ä»å¤©',
  now: 'æ­¤å»',
  backToToday: 'è¿åä»å¤©',
  ok: 'ç¢ºå®',
  timeSelect: 'é¸ææé',
  dateSelect: 'é¸ææ¥æ',
  clear: 'æ¸é¤',
  month: 'æ',
  year: 'å¹´',
  previousMonth: 'ä¸åæ (ç¿»é ä¸éµ)',
  nextMonth: 'ä¸åæ (ç¿»é ä¸éµ)',
  monthSelect: 'é¸ææä»½',
  yearSelect: 'é¸æå¹´ä»½',
  decadeSelect: 'é¸æå¹´ä»£',
  yearFormat: 'YYYYå¹´',
  dayFormat: 'Dæ¥',
  dateFormat: 'YYYYå¹´MæDæ¥',
  dateTimeFormat: 'YYYYå¹´MæDæ¥ HHæmmåssç§',
  previousYear: 'ä¸ä¸å¹´ (Controléµå å·¦æ¹åéµ)',
  nextYear: 'ä¸ä¸å¹´ (Controléµå å³æ¹åéµ)',
  previousDecade: 'ä¸ä¸å¹´ä»£',
  nextDecade: 'ä¸ä¸å¹´ä»£',
  previousCentury: 'ä¸ä¸ä¸ç´',
  nextCentury: 'ä¸ä¸ä¸ç´',
};
