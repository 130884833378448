// This icon file is generated by build/generate.ts
// tslint:disable

import { IconDefinition } from '../types';

export const YuqueFill: IconDefinition = {
  name: 'yuque',
  theme: 'fill',
  icon:
    '<svg  viewBox="64 64 896 896"><path d="M854.6 370.6c-9.9-39.4 9.9-102.2 73.4-124.4l-67.9-3.6s-25.7-90-143.6-98c-117.9-8.1-195-3-195-3s87.4 55.6 52.4 154.7c-25.6 52.5-65.8 95.6-108.8 144.7-1.3 1.3-2.5 2.6-3.5 3.7C319.4 605 96 860 96 860c245.9 64.4 410.7-6.3 508.2-91.1 20.5-.2 35.9-.3 46.3-.3 135.8 0 250.6-117.6 245.9-248.4-3.2-89.9-31.9-110.2-41.8-149.6z"/></svg>'
};
