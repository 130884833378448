// This icon file is generated by build/generate.ts
// tslint:disable

import { IconDefinition } from '../types';

export const ToolFill: IconDefinition = {
  name: 'tool',
  theme: 'fill',
  icon:
    '<svg  viewBox="64 64 896 896"><path d="M865.3 244.7c-.3-.3-61.1 59.8-182.1 180.6l-84.9-84.9 180.9-180.9c-95.2-57.3-217.5-42.6-296.8 36.7A244.42 244.42 0 0 0 419 432l1.8 6.7-283.5 283.4c-6.2 6.2-6.2 16.4 0 22.6l141.4 141.4c6.2 6.2 16.4 6.2 22.6 0l283.3-283.3 6.7 1.8c83.7 22.3 173.6-.9 236-63.3 79.4-79.3 94.1-201.6 38-296.6z"/></svg>'
};
