export default {
  // Options.jsx
  items_per_page: '/ sÃ­Ã°u',
  jump_to: 'SÃ­Ã°a',
  jump_to_confirm: 'staÃ°fest',
  page: '',

  // Pagination.jsx
  prev_page: 'Fyrri sÃ­Ã°a',
  next_page: 'NÃ¦sta sÃ­Ã°a',
  prev_5: 'Til baka 5 sÃ­Ã°ur',
  next_5: 'Ãfram 5 sÃ­Ã°ur',
  prev_3: 'Til baka 3 sÃ­Ã°ur',
  next_3: 'Ãfram 3 sÃ­Ã°ur',
};
