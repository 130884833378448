// This icon file is generated by build/generate.ts
// tslint:disable

import { IconDefinition } from '../types';

export const SecurityScanFill: IconDefinition = {
  name: 'security-scan',
  theme: 'fill',
  icon:
    '<svg  viewBox="64 64 896 896"><path d="M866.9 169.9L527.1 54.1C523 52.7 517.5 52 512 52s-11 .7-15.1 2.1L157.1 169.9c-8.3 2.8-15.1 12.4-15.1 21.2v482.4c0 8.8 5.7 20.4 12.6 25.9L499.3 968c3.5 2.7 8 4.1 12.6 4.1s9.2-1.4 12.6-4.1l344.7-268.6c6.9-5.4 12.6-17 12.6-25.9V191.1c.2-8.8-6.6-18.3-14.9-21.2zM626.8 554c-48.5 48.5-123 55.2-178.6 20.1l-77.5 77.5a8.03 8.03 0 0 1-11.3 0l-34-34a8.03 8.03 0 0 1 0-11.3l77.5-77.5c-35.1-55.7-28.4-130.1 20.1-178.6 56.3-56.3 147.5-56.3 203.8 0 56.3 56.3 56.3 147.5 0 203.8zm-158.54-45.27a80.1 80.1 0 1 0 113.27-113.28 80.1 80.1 0 1 0-113.27 113.28z"/></svg>'
};
