export default {
  // Options.jsx
  items_per_page: '/ ãã¼ã¸',
  jump_to: 'ç§»å',
  jump_to_confirm: 'ç¢ºèªãã',
  page: 'ãã¼ã¸',

  // Pagination.jsx
  prev_page: 'åã®ãã¼ã¸',
  next_page: 'æ¬¡ã®ãã¼ã¸',
  prev_5: 'å 5ãã¼ã¸',
  next_5: 'æ¬¡ 5ãã¼ã¸',
  prev_3: 'å 3ãã¼ã¸',
  next_3: 'æ¬¡ 3ãã¼ã¸',
};
