import Calendar from './calendar/pl_PL';
import DatePicker from './date-picker/pl_PL';
import Pagination from './pagination/pl_PL';
import TimePicker from './time-picker/pl_PL';

export default {
  locale: 'pl',
  Pagination,
  DatePicker,
  TimePicker,
  Calendar,
  Table: {
    filterTitle: 'Menu filtra',
    filterConfirm: 'OK',
    filterReset: 'WyczyÅÄ',
    emptyText: 'Brak danych',
    selectAll: 'Zaznacz bieÅ¼ÄcÄ stronÄ',
    selectInvert: 'OdwrÃ³Ä zaznaczenie',
  },
  Modal: {
    okText: 'OK',
    cancelText: 'Anuluj',
    justOkText: 'OK',
  },
  Popconfirm: {
    okText: 'OK',
    cancelText: 'Anuluj',
  },
  Transfer: {
    notFoundContent: 'Nie znaleziono',
    searchPlaceholder: 'Szukaj',
    itemUnit: 'obiekt',
    itemsUnit: 'obiekty',
  },
  Select: {
    notFoundContent: 'Nie znaleziono',
  },
  Upload: {
    uploading: 'WysyÅanie...',
    removeFile: 'UsuÅ plik',
    uploadError: 'BÅÄd wysyÅania',
    previewFile: 'Podejrzyj plik',
  },
};
