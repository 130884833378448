// This icon file is generated by build/generate.ts
// tslint:disable

import { IconDefinition } from '../types';

export const StepBackwardFill: IconDefinition = {
  name: 'step-backward',
  theme: 'fill',
  icon:
    '<svg viewBox="0 0 1024 1024"><path d="M347.6 528.95l383.2 301.02c14.25 11.2 35.2 1.1 35.2-16.95V210.97c0-18.05-20.95-28.14-35.2-16.94L347.6 495.05a21.53 21.53 0 0 0 0 33.9M330 864h-64a8 8 0 0 1-8-8V168a8 8 0 0 1 8-8h64a8 8 0 0 1 8 8v688a8 8 0 0 1-8 8"/></svg>'
};
